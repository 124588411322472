import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery1BarIcon from '@mui/icons-material/Battery1Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import {useMemo} from 'react';

interface Props {
  value: number;
}

const ICONS = {
  0: <Battery0BarIcon color="error" />,
  10: <Battery1BarIcon color="error" />,
  20: <Battery1BarIcon color="error" />,
  30: <Battery2BarIcon color="error" />,
  40: <Battery2BarIcon color="warning" />,
  50: <Battery3BarIcon color="warning" />,
  60: <Battery3BarIcon color="warning" />,
  70: <Battery4BarIcon color="warning" />,
  80: <Battery5BarIcon color="success" />,
  90: <Battery6BarIcon color="success" />,
  100: <BatteryFullIcon color="success" />,
};

export const BatteryIcon = ({value}: Props) => {
  const icon = useMemo(() => {
    let index: keyof typeof ICONS = 0;

    if (value >= 100) {
      index = 100;
    } else if (value >= 90) {
      index = 90;
    } else if (value >= 80) {
      index = 80;
    } else if (value >= 70) {
      index = 70;
    } else if (value >= 60) {
      index = 60;
    } else if (value >= 50) {
      index = 50;
    } else if (value >= 40) {
      index = 40;
    } else if (value >= 30) {
      index = 30;
    } else if (value >= 20) {
      index = 20;
    } else if (value >= 10) {
      index = 10;
    }

    return ICONS[index];
  }, [value]);

  return icon;
};

enum BackendMessageType {
  ERROR = 'error',
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
}

type BackendMessage = {
  client_id: number;
  message: string;
  type: BackendMessageType | undefined;
};

export enum Actions {
  FETCH_MAPS = 'fetchMapGrid',
}

type Action = {
  action: Actions;
};

export interface BackendResponse {
  version: string;
  messages?: BackendMessage[];
  actions?: Action[];
}

export enum ConnectionStatus {
  GOOD = 'good',
  POOR = 'poor',
  DISCONNECTED = 'disconnected',
}

export interface BackendStatus {
  version: string;
  connected: boolean;
  delay: number;
  connection_status: ConnectionStatus;
  messages?: BackendMessage[];
  actions?: Action[];
}

export interface BackendRestartResponse {
  message: string;
  running: boolean;
}

export interface BackendSummaryQuery {
  lines: number;
}

enum State {
  RUNNING = 'running',
  EXITED = 'exited',
}

export interface BackendSummaryResponse {
  id: string;
  short_id: string;
  running: boolean;
  state: State;
  status: string;
  lines: number;
  info: string;
  error: string;
  infoFilePath: string;
  errorFilePath: string;
}

import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';
import _ from 'lodash';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import reduxSelectors from '../../redux/selectors';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'SelectProps'
  | 'sx'
> & {
  value?: number | number[] | null;
  multiple?: boolean;
  nullLabel?: string;
  onChange?: (v: number | number[]) => void;
};

export const ZonesSelect = (props: Props) => {
  // This variable is needed only for multiple mode
  const [selected, setSelected] = useState<number[]>(props.multiple ? props.value as number[] : []);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const options = assets.zones;

  const valueName = props.multiple
    ? (selected?.length > 0 ? selected : ['all'])
    : (props.value || 'all');

  const handleChange: InputProps['onChange'] = (event: any) => {
    // For single mode we return value immediately
    if (!props.multiple) {
      return props.onChange?.(
        event.target.value === 'all'
          ? null
          : event.target.value
      );
    }

    // For multiple mode we need to update array of selected items
    // and return them later when dropdown is closed
    const isSelectAll = _.last(event.target.value) === 'all';
    setSelected(
      isSelectAll
        ? []
        : event.target.value.filter((i: any) => i !== 'all')
    );
  };

  const handleClose = () => {
    // For multiple mode we return value only after dropdown is closed
    if (props.multiple) {
      props.onChange?.(selected);
    }
  };

  return (
    <TextField
      {...props}
      label={props.label || 'Sections'}
      value={valueName}
      select
      SelectProps={{
        multiple: props.multiple,
        onClose: handleClose,
        MenuProps: {
          sx: {maxHeight: '500px'},
        },
      }}
      onChange={handleChange}
    >
      <MenuItem value="all">
        {props.nullLabel ?? 'All Sections' }
      </MenuItem>

      {options.map((option) => (
        <MenuItem
          key={option.id ?? ''}
          value={option.id ?? ''}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

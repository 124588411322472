import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {cloneDeep} from 'lodash';
import {enqueueSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {MeResponse} from '../../interfaces/Me';
import {User, UserItemResponse} from '../../interfaces/User';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

interface Props {
  onClose: VoidFunction;
}

const emailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Field is required')
    .email('Please enter valid email address'),
});

const MyAccount = ({onClose}: Props) => {
  const me = useAppSelector(({app}) => app.me);

  const [fetchedData, setFetchedData] = useState<MeResponse | null>(
    cloneDeep(me)
  );
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const reduxDispatch = useAppDispatch();

  const emailFormik = useFormik({
    initialValues: {email: fetchedData?.email},
    validationSchema: emailValidationSchema,
    onSubmit: async ({email}) =>
      updateData({email, username: fetchedData?.username}),
  });

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<MeResponse>(`${apiBaseUrl}/user/me`);
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setApiErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    emailFormik.setValues({email: fetchedData?.email});
  }, [fetchedData?.email]);

  const updateData = async (data: Partial<User>) => {
    setUpdateInProgress(true);

    try {
      await API.patch<UserItemResponse>(
        `${apiBaseUrl}/user/${fetchedData?.id}`,
        data
      );
      reduxDispatch(reduxActions.app.fetchMe);
      onClose();
      enqueueSnackbar('User email updated successfully', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setApiErrors(messages);
    }

    setUpdateInProgress(false);
  };

  return (
    <Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {apiErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box my={4}>
        <TextField
          value={fetchedData?.name}
          fullWidth
          name="name"
          label="Name"
          size="small"
          disabled
        />
      </Box>

      <Box my={4}>
        <TextField
          value={fetchedData?.username}
          fullWidth
          name="username"
          label="Username"
          size="small"
          disabled
        />
      </Box>

      <Box my={4}>
        <TextField
          value={emailFormik.values.email}
          fullWidth
          name="email"
          label="Email"
          size="small"
          error={!!emailFormik.touched.email && !!emailFormik.errors.email}
          helperText={emailFormik.touched.email && emailFormik.errors.email}
          onChange={emailFormik.handleChange}
        />
      </Box>

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={onClose}>Close</Button>

        <LoadingButton
          sx={{ml: 1}}
          type="submit"
          variant="contained"
          color="error"
          loading={updateInProgress}
          onClick={() => emailFormik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default MyAccount;

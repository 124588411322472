import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {Box, Button} from '@mui/material';

import {DashboardEntity} from '../../../interfaces/Dashboard';
import TruncatedText from '../../common/TruncantedText';
import {
  DashboardPanelActionsSlotArea,
  DashboardPanelActionsSlotProvider,
} from '../../dashboard-panels/DashboardPanelActionsSlot';
import DashboardPanelItem from '../../dashboard-panels/DashboardPanelItem';
import {
  DashboardPanelTitleSlotArea,
  DashboardPanelTitleSlotProvider,
} from '../../dashboard-panels/DashboardPanelTitleSlot';

interface Props {
  value: DashboardEntity;
  entities?: DashboardEntity[];
  disabled?: boolean;
  onUpdate?: (value: DashboardEntity) => void;
  onDelete?: (value: DashboardEntity) => void;
  onOpenHistory?: (
    id: number | string | number[],
    entity:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const DashboardEntityItemPanel = ({
  value,
  entities,
  disabled,
  onUpdate,
  onDelete,
  onOpenHistory,
}: Props) => {
  return (
    <DashboardPanelTitleSlotProvider>
      <DashboardPanelActionsSlotProvider>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 1,
            alignItems: 'center',
            position: 'relative',
            border: '1px solid',
            borderColor: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
            borderRadius: 1,
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns="1fr auto 1fr"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
            gap={2}
            width="100%"
          >
            <Box>
              <DashboardPanelActionsSlotArea />
            </Box>

            <Box
              className="panel-title"
              fontWeight={500}
              fontSize={20}
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <TruncatedText>
                <DashboardPanelTitleSlotArea />
              </TruncatedText>
            </Box>

            {!disabled && (
              <Box display="flex" justifyContent="end" alignItems="center">
                <Button
                  sx={{ml: 1, minWidth: 'auto', p: 1}}
                  onClick={() => onDelete?.(value)}
                >
                  <DeleteIcon fontSize="small" />
                </Button>

                <Button
                  className="entity-drag-button"
                  sx={{ml: 1, minWidth: 'auto', p: 1}}
                >
                  <DragIndicatorIcon fontSize="small" />
                </Button>
              </Box>
            )}
          </Box>

          <DashboardPanelItem
            code={value.panel?.code}
            value={value.panel?.data}
            entities={entities}
            onUpdate={(v) => {
              onUpdate?.({
                ...value,
                panel: {
                  ...value.panel,
                  data: v,
                },
              });
            }}
            onOpenHistory={onOpenHistory}
          />
        </Box>
      </DashboardPanelActionsSlotProvider>
    </DashboardPanelTitleSlotProvider>
  );
};

export default DashboardEntityItemPanel;

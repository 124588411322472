import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {getMergedConfigurations} from '../../utils/configuration';
import {COLORED_ICONS} from '../../utils/icons';
import {tweakLabelForMiner} from '../../utils/macAddress';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface EventsConfigurationConnectValues {
  [key: string]: string | number | null;
}

const FIELDS_BY_SECTIONS = {
  communicationStatus: ['hazard-ai.hazard_ai_no_heartbeat_period'],
  autoRefresh: ['hazard-ai.hazard_ai_autorefresh_rate'],

  soundGlobal: [
    'hazard-ai.sound_new_event',
    'hazard-ai.hazard_ai_sound_interval',
  ],

  eventSound: [
    'hazard-ai-sound.am_sound_801',
    'hazard-ai-sound.am_sound_802',
    'hazard-ai-sound.am_sound_803',
    'hazard-ai-sound.am_sound_804',
    'hazard-ai-sound.am_sound_805',
    'hazard-ai-sound.am_sound_806',
  ],

  eventColor: [
    'hazard-ai-color.ha_color_801',
    'hazard-ai-color.ha_color_802',
    'hazard-ai-color.ha_color_803',
    'hazard-ai-color.ha_color_804',
    'hazard-ai-color.ha_color_805',
    'hazard-ai-color.ha_color_806',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse | null
): EventsConfigurationConnectValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse | null
): EventsConfigurationConnectValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface Props {
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface MyHazardAIConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const MyHazardAIConfig = forwardRef<
  MyHazardAIConfigRef,
  React.PropsWithChildren<Props>
>(({onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(myConfigurations)),
  }));

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const reduxDispatch = useDispatch();
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);

  /*********/
  /* fetch */
  /*********/

  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const params = {
        section: JSON.stringify([
          'hazard-ai',
          'hazard-ai-color',
          'hazard-ai-sound',
        ]),
      };
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        {params}
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/

  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);
    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        data
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/

  const [resetInProgress, setResetInProgress] = useState(false);
  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);
    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        data
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: ['hazard-ai', 'hazard-ai-color', 'hazard-ai-sound'],
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(myConfigurations),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: ['hazard-ai', 'hazard-ai-color', 'hazard-ai-sound'],
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(myConfigurations);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [myConfigurations]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(myConfigurations)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [myConfigurations]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Auto Refresh Settings
            </Box>

            <Grid container spacing={4}>
              {FIELDS_BY_SECTIONS.autoRefresh.map((name) => {
                return (
                  <Grid key={name} item xs={12} lg={6}>
                    <TextField
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? 'None'}
                      name={name}
                      size="small"
                      type="number"
                      select
                      onChange={(event) => {
                        const value =
                          event.target.value === 'None'
                            ? null
                            : event.target.value;
                        formik.setValues({...formik.values, [name]: value});
                      }}
                    >
                      {['None', 30, 60, 120].map((i) => (
                        <MenuItem key={i ?? ''} value={i ?? ''}>
                          {i ?? 'None'}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Communication Status
            </Box>

            <Grid container spacing={4}>
              {FIELDS_BY_SECTIONS.communicationStatus.map((name) => {
                return (
                  <Grid key={name} item xs={12} lg={6}>
                    <Box key={name}>
                      <TextField
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? ''}
                        name={name}
                        type="number"
                        size="small"
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Default Event Sounds
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Global
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(0, 1).map((name) => (
                    <Box key={name} mb={2}>
                      <SoundSelect
                        fullWidth
                        label={tweakLabelForMiner(t(keysData[name]?.label))}
                        value={formik.values[name] ?? ''}
                        name={name}
                        size="small"
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(1).map((name) => (
                    <Box key={name} mb={2}>
                      <TextField
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? 'None'}
                        name={name}
                        type="number"
                        size="small"
                        select
                        onChange={(event) => {
                          const value =
                            event.target.value === 'None'
                              ? null
                              : event.target.value;
                          formik.setValues({...formik.values, [name]: value});
                        }}
                      >
                        {['None', 10, 15, 30, 60, 120].map((i) => (
                          <MenuItem key={i ?? ''} value={i ?? ''}>
                            {i ?? 'None'}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Safeye Nodes
              </Box>
              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.eventSound.map((name) => (
                  <Grid key={name} item xs={6}>
                    <SoundSelect
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} xl={6}>
          <Paper sx={{p: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={3}>
              Default Event Colors
            </Box>

            <Box mb={3}>
              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.eventColor.map((name) => (
                  <Grid key={name} item xs={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      icons={COLORED_ICONS.communicationNodes}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

import {Permission} from './acl';

export const PANEL_CODES = [
  'HazardAI',
  'HazardAIDectionLog',
  'HazardAIMachineUtilizationChart',
  'HazardAIMachineSpeedChart',
  'HazardAISummaryChart',
  'HazardHeatMapReports',
  'Map',
  'AlarmHistoryReports',
  'AlarmLogReports',
  'AlarmGrid',
  'EmployeeAssetsGrid',
  'MachineDetailsReport',
  'MachineOverviewReport',
  'MachineHistoryReport',
  'MachineStatusReport',
  'EmployeeHistoryReport',
  'EmployeeOverviewReport',
  'MachineInteractionReport',
  'MachineSummaryReport',
  'CheckoutStationOverviewReport',
  'CheckoutStationDetailsReport',
  'InteractionsPerMachine',
  'TopEmployeeInteractions',
  'ConnectView',
  'MCronLogs',
  'MProcessorLogs',
  'SystemServicesTable',
  'CommtracNodeTrackingReports',
  'NodeTrackingReports',
  'NetworkDiagnostics',
  'BackHaulers',
  'InteractionsLastDays',
  'Nginx',
  'Backend',
  'Mqtt',
] as const;

export type PanelCode = (typeof PANEL_CODES)[number];

export const PANEL_PRODUCT_CODES = [
  'core',
  'connect',
  'wifi',
  'belts',
  'proximity',
  'ams',
  'shaft_clearance',
  'ventilation',
  'hazard',
] as const;

export type PanelProductCode = (typeof PANEL_PRODUCT_CODES)[number];

export const PANELS_DATA: {
  [key in PanelCode]: {
    products: PanelProductCode[];
    permissions?: Permission[];
    roles?: number[];
    accessChecker?: () => boolean;
  };
} = {
  MachineDetailsReport: {
    products: ['proximity'],
  },
  MachineOverviewReport: {
    products: ['proximity'],
  },
  MachineHistoryReport: {
    products: ['proximity'],
  },
  MachineStatusReport: {
    products: ['proximity'],
  },
  EmployeeHistoryReport: {
    products: ['proximity'],
  },
  EmployeeOverviewReport: {
    products: ['proximity'],
  },
  MachineInteractionReport: {
    products: ['proximity'],
  },
  MachineSummaryReport: {
    products: ['proximity'],
  },
  CheckoutStationOverviewReport: {
    products: ['proximity'],
  },
  CheckoutStationDetailsReport: {
    products: ['proximity'],
  },
  InteractionsPerMachine: {
    products: ['proximity'],
  },
  TopEmployeeInteractions: {
    products: ['proximity'],
  },
  ConnectView: {
    products: ['connect', 'proximity'],
  },
  HazardAI: {
    products: ['hazard'],
  },
  HazardAIDectionLog: {
    products: ['hazard'],
  },
  HazardAIMachineUtilizationChart: {
    products: ['hazard'],
  },
  HazardAIMachineSpeedChart: {
    products: ['hazard'],
  },
  HazardAISummaryChart: {
    products: ['hazard'],
  },
  HazardHeatMapReports: {
    products: ['hazard'],
  },
  Map: {
    products: ['connect'],
  },
  InteractionsLastDays: {
    products: ['proximity'],
  },
  MCronLogs: {
    products: ['core'],
    permissions: ['get::/m-cron/status'],
  },
  MProcessorLogs: {
    products: ['core'],
  },
  SystemServicesTable: {
    products: ['core'],
  },
  CommtracNodeTrackingReports: {
    products: ['connect'],
  },
  NodeTrackingReports: {
    products: ['connect'],
  },
  NetworkDiagnostics: {
    products: ['connect'],
  },
  BackHaulers: {
    products: ['core'],
  },
  AlarmHistoryReports: {
    products: ['connect'],
  },
  AlarmLogReports: {
    products: ['connect'],
  },
  AlarmGrid: {
    products: ['connect'],
  },
  EmployeeAssetsGrid: {
    products: ['connect'],
  },
  Nginx: {
    products: ['core'],
  },
  Backend: {
    products: ['core'],
  },
  Mqtt: {
    products: ['core'],
  },
};

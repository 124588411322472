import {Alert, Backdrop, Box, CircularProgress, useTheme} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import * as _ from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  HazardAIMachineSpeedQuery,
  HazardAIMachineSpeedResponse,
} from '../../../interfaces/HazardAIMachineSpeedChart';
import reduxSelectors from '../../../redux/selectors';
import {
  usePanel,
  usePanelFilter,
} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SafeyeNodeSelector} from '../../selectors/SafeyeNodeSelector';
import {SitesSelect} from '../../selectors/SitesSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import HazardAISummaryGrid from '../HazardAIMachineUtilizationChart/HazardAISummaryGrid';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

const HazardAIMachineSpeedChart = (props?: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const theme = useTheme();
  const [panel] = usePanel();
  const {filter, setFilter} = usePanelFilter();

  const [fetchedData, setFetchedData] =
    useState<HazardAIMachineSpeedResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<HazardAIMachineSpeedQuery>(() => ({
    date_start: filter.params?.date_start ?? props?.value?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
    date_end: filter.params?.date_end ?? props?.value?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
    safeye_node_id: _.has(filter.params, 'safeye_node_ids')
      ? filter.params?.safeye_node_ids
      : props?.value?.params?.safeye_node_id,
    site_id: _.has(filter.params, 'site_ids')
      ? filter.params?.site_ids
      : props?.value?.params?.site_id,
  }), [filter]);

  const data = useMemo(() => {
    if (fetchedData) {
      return fetchedData.map((item) => ({
        ...item,
        max_speed: Number(item.max_speed.toString()),
      }));
    }
  }, [fetchedData]);

  useEffect(() => {
    if (params) {
      fetchData(params);
      props?.onUpdate?.({...props.value, params});
    }
  }, [params]);

  const fetchData = useCallback(
    async (params: HazardAIMachineSpeedQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/hazard-ai/machine-speed-chart`;
        const resp = await API.get<HazardAIMachineSpeedResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  const CustomTooltip = (options: any) => {
    if (options.active && options.payload?.length) {
      const item = options.payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
            borderStyle: 'solid',
            borderWidth: '1px',
            padding: '4px 8px',
          }}
        >
          <strong>{`${item.safeye_node_name} (${item.safeye_node_id})`}</strong>
          <br />
          <span>{`Max Speed : ${item.max_speed}`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 38px)"
      width="100%"
      overflow="hidden"
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      {/* Filters */}
      <Box display="flex" gap={1} py={1}>
        <Box maxWidth={400} minWidth={200}>
          <SafeyeNodeSelector
            size="small"
            multiple
            fullWidth
            value={params.safeye_node_id as unknown as number[]}
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    safeye_node_ids: {
                      $set: v as unknown as string[],
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box maxWidth={400} minWidth={200}>
          <SitesSelect
            size="small"
            multiple
            fullWidth
            value={params.site_id}
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    site_ids: {
                      $set: v as number[],
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box maxWidth={400}>
          <DateRangeSelect
            value={[
              dayjs(params.date_start).toDate(),
              dayjs(params.date_end).toDate(),
            ]}
            size="small"
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    date_start: {
                      $set: v?.[0]
                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                        : undefined,
                    },
                    date_end: {
                      $set: v?.[1]
                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                        : undefined,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </Box>

      {/* Errors */}
      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 56px)"
        gap={1}
      >
        {/* Chart */}
        <Box
          width="100%"
          height="50%"
          bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
        >
          {!fetchedInProgress && !!fetchedData?.length && (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                margin={{
                  top: 30,
                  bottom: 10,
                  right: 10,
                }}
              >
                <XAxis dataKey="safeye_node_id" />
                <YAxis />
                <TooltipRecharts cursor={false} content={<CustomTooltip />} />
                <Legend />
                <Bar
                  dataKey="max_speed"
                  name="Max Speed"
                  label={{
                    position: 'top',
                    fill: theme.palette.text.secondary,
                  }}
                  fill="purple"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
          {(fetchedInProgress || !fetchedData?.length) && (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {fetchedInProgress ? 'Loading...' : 'No data to display'}
            </Box>
          )}
        </Box>

        {/* Grid */}
        <HazardAISummaryGrid
          value={{
            ...props?.value,
            shownFields: props?.value?.shownFields || [
              'safeye_node_id',
              'safeye_node_name',
              'max_speed'
            ],
          }}
        />
      </Box>
    </Box>
  );
};

export default HazardAIMachineSpeedChart;

import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import orderBy from 'lodash/orderBy';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  WifiPointLongTermHistory,
  WifiPointLongTermHistoryListQuery,
  WifiPointLongTermHistoryListResponse,
} from '../../../interfaces/WifiPointLongTermHistory';
import reduxSelectors from '../../../redux/selectors';
import {CHART_COLORS} from '../../../utils/colors';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: WifiPointLongTermHistoryReportsData;
  onUpdate?: (value?: WifiPointLongTermHistoryReportsData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'serial_number',
  'min',
  'max',
  'med',
  'avg',
  'date',
];
const defaultDateRangeValue = 1;
const defaultDateRangeUnit = 'month';

interface WifiPointLongTermHistoryReportsDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: number[];
  selectAll: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
  };
}

export interface WifiPointLongTermHistoryReportsData {
  activeId?: number;
  openedItems: WifiPointLongTermHistoryReportsDataTab[];
}

export const getWifiPointLongTermHistoryReportsData =
  (): WifiPointLongTermHistoryReportsData => ({
    activeId: undefined,
    openedItems: [],
  });

const getWifiPointLongTermHistoryReportDataTab = (
  id: number
): WifiPointLongTermHistoryReportsDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs()
      .subtract(defaultDateRangeValue, defaultDateRangeUnit)
      .format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
});

export const WifiPointLongTermHistoryReports = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () => value ?? getWifiPointLongTermHistoryReportsData(),
    [value]
  );

  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex] &&
          config.openedItems[openedItemIndex].params
          ? config.openedItems[openedItemIndex]
          : getWifiPointLongTermHistoryReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const nodes = useAppSelector(({assets}) =>
    assets.nodes.filter((i) => i.node_type === 'wifi_point')
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => nodes.find((i) => i.wifi_id === o.id)?.name
      ),
    [config.openedItems, nodes]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<WifiPointLongTermHistoryListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<WifiPointLongTermHistoryListQuery | null>(
    () =>
      openedItem?.id
        ? {
            ID: openedItem.id,
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: WifiPointLongTermHistoryListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const resp = await API.get<WifiPointLongTermHistoryListResponse>(
          `${apiBaseUrl}/node/${openedItem?.id}/wifi-point/long-events`,
          {
            params,
          }
        );
        setFetchedData(resp.data);
        // collect all IDs
        if (openedItem && openedItem.selectAll) {
          openedItem.selectedIds = resp.data.items.map((el) => el.id);
        }
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);
  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*************/
  /* data grid */
  /*************/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<WifiPointLongTermHistory>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      sortable: true,
      headerName: 'ID',
    },
    {
      field: 'serial_number',
      sortable: true,
    },
    {
      field: 'min',
      sortable: true,
      headerName: 'Minimum',
    },
    {
      field: 'max',
      sortable: true,
      headerName: 'Maximum',
    },
    {
      field: 'med',
      sortable: true,
      headerName: 'Median',
    },
    {
      field: 'avg',
      sortable: true,
      headerName: 'Average',
    },
    {
      field: 'date',
      sortable: true,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: fetchedData?.items.map((el) => el.id),
              },
              selectAll: {
                $set: true,
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: [],
              },
              selectAll: {
                $set: false,
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedAll) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const dateTimeFormatter = (timestamp: string) => {
    return dayjs(timestamp).format('ddd, MMM D, YYYY');
  };

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);

  const lines = [
    {
      key: 'min',
      label: 'Min',
    },
    {
      key: 'max',
      label: 'Max',
    },
    {
      key: 'med',
      label: 'Med',
    },
    {
      key: 'avg',
      label: 'Avg',
    },
  ];
  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);

  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  const chartData = useMemo(
    () => orderBy(selectedRows, (i) => i.date),
    [selectedRows]
  );

  return (
    <>
      <DashboardPanelTitleSlot>
        WiFi Nodes Long Term Tracking Reports
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Box
            width="100%"
            height="100%"
            minWidth={400}
            pt={2}
            pb={2}
            pr={6}
            bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData} margin={{left: 20, top: 40}}>
                <XAxis dataKey="date" />
                <YAxis>
                  <Label value="Latency (ms)" dx={-30} angle={-90} />
                </YAxis>
                <RechartsTooltip
                  labelStyle={{color: 'black'}}
                  labelFormatter={dateTimeFormatter}
                />
                <CartesianGrid opacity={0.3} />
                <Legend onClick={(item) => toggleKey(item.dataKey)} />
                {lines?.map(({key, label}, idx) => (
                  <Line
                    key={key}
                    dataKey={key}
                    name={label}
                    hide={hiddenKeys.includes(key)}
                    stroke={CHART_COLORS[idx]}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Box>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {nodes?.map((it, key: number) => (
                          <MenuItem
                            key={key}
                            onClick={() => {
                              onUpdate?.(
                                update(value, {
                                  activeId: {
                                    $set: it.wifi_id ?? undefined,
                                  },
                                  openedItems: {
                                    $set: [
                                      ...config.openedItems,
                                      {
                                        id: it.wifi_id ?? -1,
                                      } as WifiPointLongTermHistoryReportsDataTab,
                                    ],
                                  },
                                })
                              );
                              popupState.close();
                            }}
                          >
                            {it.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={(_event, v) => {
                    if (v) {
                      onUpdate?.(
                        update(config, {
                          activeId: {
                            $set: v,
                          },
                        })
                      );
                    }
                  }}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? 'Undefined'}
                          onClose={() => {
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? undefined
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Wifi Points Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};

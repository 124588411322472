import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import PrintIcon from '@mui/icons-material/Print';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  CommunicationNodeEvent,
  CommunicationNodeEventListQuery,
  CommunicationNodeEventListResponse,
} from '../../../interfaces/CommunicationNodeEvent';
import {getBooleanValue} from '../../../utils/boolean';
import {communicationNodeMessageTypes} from '../../../utils/message-types';
import {signalBytesToDbm} from '../../../utils/node';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MessageTypeSelectLegacy} from '../../selectors/MessageTypeSelectLegacy';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: CommunicationNodeHistoryReportsData;
  onUpdate?: (value?: CommunicationNodeHistoryReportsData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'battery',
  'timestamp',
  'message_type',
  'routing_root_address',
  'routing_parent_address',
  'routing_parent_rssi',
  'routing_number_of_hops',
  'timing_root_address',
  'timing_parent_address',
  'timing_parent_rssi',
  'timing_number_of_hops',
  'lost_routing_parents',
  'lost_timing_parents',
  'changed_routing_parents',
  'changed_timing_parents',
  'network_entry',
  'network_entry_reason',
  'idle_subframes',
  'cc1110_version',
  'pic_version',
];

export interface CommunicationNodeHistoryReportsData {
  activeId?: number;
  openedItems: {
    id: number;
    refreshInterval?: number | null;
    params?: {
      date_start?: string;
      date_end?: string;
      message_type?: string;
      page?: number;
      limit?: number;
      order?: string;
      dir?: 'ASC' | 'DESC';
    };
  }[];
}

export const getCommunicationNodeHistoryReportsData =
  (): CommunicationNodeHistoryReportsData => ({
    activeId: undefined,
    openedItems: [],
  });

export const CommunicationNodeHistoryReports = ({value, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(
    () => value ?? getCommunicationNodeHistoryReportsData(),
    [value]
  );

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () => (openedItemIndex !== -1 ? config.openedItems[openedItemIndex] : null),
    [openedItemIndex, config.openedItems]
  );

  const nodes = useAppSelector(({assets}) =>
    assets.nodes.filter((i) => i.node_type === 'communication_node')
  );

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => nodes.find((i) => i.cn_external_id === o.id)?.name
      ),
    [config.openedItems, nodes]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<CommunicationNodeEventListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const params = useMemo<CommunicationNodeEventListQuery | null>(
    () =>
      openedItem?.id
        ? {
            external_id: openedItem?.id,
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            message_type: openedItem.params?.message_type ?? null,
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'timestamp',
            dir: openedItem.params?.dir ?? 'DESC',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: CommunicationNodeEventListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const resp = await API.get<CommunicationNodeEventListResponse>(
          `${apiBaseUrl}/communication-node/event-grid`,
          {params}
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);
  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*************/
  /* data grid */
  /*************/
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<CommunicationNodeEvent>[] = [
    {
      field: 'battery',
      renderHeader: () => (
        <Tooltip title="Battery Level">
          <BatteryChargingFullIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      width: 50,
      valueGetter: ({row}) =>
        row.battery !== null ? +row.battery / 100 : null,
      renderCell: ({value}) => {
        if (value === null) {
          return <QuestionMarkIcon fontSize="small" />;
        }
        return <>{value}</>;
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      valueGetter: ({row}) => row.id ?? 0,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
      width: 150,
    },
    {
      field: 'message_type',
      headerName: 'Message Type',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.message_type) {
          const value = +row.message_type;
          return (
            communicationNodeMessageTypes.find((i) => i.code === value)?.name ??
            value
          );
        }
        return row.message_type;
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
    },
    {
      field: 'message_id',
      headerName: 'Message ID',
      sortable: true,
    },
    {
      field: 'routing_root_address',
      headerName: 'Root Address',
      sortable: true,
    },
    {
      field: 'routing_parent_address',
      headerName: 'Parent Address',
      sortable: true,
    },
    {
      field: 'routing_parent_rssi',
      headerName: 'Parent RSSI',
      sortable: true,
      valueGetter: ({row}) => {
        return signalBytesToDbm(row.routing_parent_rssi);
      },
    },
    {
      field: 'routing_number_of_hops',
      headerName: 'Hops to Root',
      sortable: true,
    },
    {
      field: 'timing_root_address',
      headerName: 'Timing Root Address',
      sortable: true,
    },
    {
      field: 'timing_parent_address',
      headerName: 'Timing Parent Address',
      sortable: true,
    },
    {
      field: 'timing_parent_rssi',
      headerName: 'Timing Parent RSSI',
      sortable: true,
      valueGetter: ({row}) => {
        return signalBytesToDbm(row.timing_parent_rssi);
      },
    },
    {
      field: 'timing_number_of_hops',
      headerName: 'Timing Hops to Root',
      sortable: true,
    },
    {
      field: 'lost_routing_parents',
      headerName: 'Lost Routing Parents',
      sortable: true,
    },
    {
      field: 'lost_timing_parents',
      headerName: 'Lost Timing Parents',
      sortable: true,
    },
    {
      field: 'changed_routing_parents',
      headerName: 'Changed Routing Parents',
      sortable: true,
    },
    {
      field: 'changed_timing_parents',
      headerName: 'Changed Timing Parents',
      sortable: true,
    },
    {
      field: 'network_entry',
      headerName: 'Network Entry',
      sortable: true,
    },
    {
      field: 'network_entry_reason',
      headerName: 'Network Entry Reason',
      sortable: true,
    },
    {
      field: 'idle_subframes',
      headerName: 'Idle Subframes',
      sortable: true,
    },
    {
      field: 'cc1110_version',
      headerName: 'CC1110 Version',
      sortable: true,
    },
    {
      field: 'pic_version',
      headerName: 'PIC Version',
      sortable: true,
    },
    {
      field: 'heartbeat_count',
      headerName: 'Heartbeat Count',
      sortable: true,
    },
    {
      field: 'message_overflow_count',
      headerName: 'Message Overflow Count',
      sortable: true,
    },
    {
      field: 'neighbor_table_count',
      headerName: 'Neighbor Table Count',
      sortable: true,
    },
    {
      field: 'neighbor_table_full',
      headerName: 'Neighbor Table Full',
      sortable: true,
      valueFormatter: ({row}) =>
        `${getBooleanValue(row.neighbor_table_full) ?? ''}`,
    },
    {
      field: 'routing_above_threshold',
      headerName: 'Routing Above Threshold',
      sortable: true,
      valueFormatter: ({row}) =>
        `${getBooleanValue(row.neighbor_table_full) ?? ''}`,
    },
    {
      field: 'timing_above_threshold',
      headerName: 'Timing Above Threshold',
      sortable: true,
      valueFormatter: ({row}) =>
        `${getBooleanValue(row.neighbor_table_full) ?? ''}`,
    },
  ];

  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);

  const dataGridRef = useRef<DataGridRef>(null);

  return (
    <>
      <DashboardPanelTitleSlot>
        Communication Node Tracking Reports
      </DashboardPanelTitleSlot>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="hidden"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          py={1.5}
          bgcolor={(theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
          }
        >
          {isOpenAwayFromConnectView && (
            <Box px={1.5}>
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <Button variant="outlined" {...bindTrigger(popupState)}>
                      Select an Object
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      {nodes?.map((it, key: number) => (
                        <MenuItem
                          key={key}
                          onClick={() => {
                            onUpdate?.(
                              update(value, {
                                openedItems: {
                                  $set: [
                                    ...config.openedItems,
                                    {
                                      id: it.cn_external_id ?? -1,
                                    },
                                  ],
                                },
                              })
                            );
                            popupState.close();
                          }}
                        >
                          {it.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </PopupState>
            </Box>
          )}
          {config.openedItems.length ? (
            <Box>
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={(_event, v) => {
                  if (v) {
                    onUpdate?.(
                      update(config, {
                        activeId: {
                          $set: v,
                        },
                      })
                    );
                  }
                }}
              >
                {config.openedItems.map((i, idx) => (
                  <Tab
                    key={i.id}
                    value={i.id}
                    label={
                      <TabLabel
                        name={tabNames?.[idx] ?? 'Undefined'}
                        onClose={() => {
                          onUpdate?.(
                            update(value, {
                              activeId: {
                                $set:
                                  config.activeId === i.id
                                    ? undefined
                                    : config.activeId,
                              },
                              openedItems: {
                                $set:
                                  config.openedItems.filter(
                                    (o) => o.id && o.id !== i.id
                                  ) ?? [],
                              },
                            })
                          );
                        }}
                      />
                    }
                  />
                ))}
              </Tabs>
            </Box>
          ) : (
            <Box minWidth={400} px={1.5}>
              <Alert severity="warning">No Communication Nodes Opened</Alert>
            </Box>
          )}

          <Box display="flex" flexDirection="column" px={1.5}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Box display="flex" gap={1}>
                <Box minWidth={400}>
                  <DateRangeSelect
                    value={
                      openedItem
                        ? [
                            dayjs(openedItem.params?.date_start).toDate(),
                            dayjs(openedItem.params?.date_end).toDate(),
                          ]
                        : undefined
                    }
                    size="small"
                    disabled={!openedItem}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  params: {
                                    ...openedItem.params,
                                    date_start: v?.[0]
                                      ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                      : undefined,
                                    date_end: v?.[0]
                                      ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                      : undefined,
                                  },
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />
                </Box>

                <MessageTypeSelectLegacy
                  value={openedItem?.params?.message_type?.split(',') ?? []}
                  size="small"
                  disabled={!openedItem}
                  entity="node"
                  onChange={(e) => {
                    if (openedItem) {
                      onUpdate?.(
                        update(config, {
                          openedItems: {
                            [openedItemIndex]: {
                              $set: {
                                ...openedItem,
                                params: {
                                  ...openedItem.params,
                                  message_type:
                                    (
                                      e.target.value as unknown as string[]
                                    ).join(',') || undefined,
                                },
                              },
                            },
                          },
                        })
                      );
                    }
                  }}
                />
              </Box>

              <Box display="flex">
                <ButtonGroup disabled={!openedItem}>
                  <Button
                    size="small"
                    onClick={() => params && fetchData(params)}
                  >
                    <RefreshIcon />
                  </Button>

                  <AutoRefreshSelect
                    value={openedItem?.refreshInterval ?? null}
                    onChange={(v) => {
                      if (openedItem) {
                        onUpdate?.(
                          update(config, {
                            openedItems: {
                              [openedItemIndex]: {
                                $set: {
                                  ...openedItem,
                                  refreshInterval: v,
                                },
                              },
                            },
                          })
                        );
                      }
                    }}
                  />

                  <Button
                    size="small"
                    onClick={() => dataGridRef.current?.printTable()}
                  >
                    <PrintIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>

        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1199}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => params && fetchData(params)}
          >
            {error}
          </Alert>
        ))}

        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          shownFields={shownFields}
          pagination
          paginationMode="server"
          size="small"
          sortBy={
            params?.order
              ? {
                  field: params?.order,
                  dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                }
              : null
          }
          sortingMode="server"
          page={params?.page}
          pageSize={params?.limit}
          rowCount={fetchedData?.count}
          sxFooter={{
            bgcolor: (theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
          }}
          onPageChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        params: {
                          ...openedItem.params,
                          page: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onPageSizeChange={(v) => {
            if (openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        params: {
                          ...openedItem.params,
                          page: 0,
                          limit: v,
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onSort={(v) => {
            if (v && openedItem) {
              onUpdate?.(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        params: {
                          ...openedItem.params,
                          order: v.field,
                          dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                        },
                      },
                    },
                  },
                })
              );
            }
          }}
          onShownFieldsChange={setShownFields}
        />
      </Box>
    </>
  );
};

import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';
import _ from 'lodash';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import reduxSelectors from '../../redux/selectors';

type Props = {
  value?: number[] | undefined;
  onChange: (v: number[]) => void;
};

export const SectionMultipleSelect = ({...props}: Props & TextFieldProps) => {
  const [selected, setSelected] = useState<number[]>(props.value ?? []);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const options = assets.zones;

  const handleChange: InputProps['onChange'] = (event: any) => {
    const isSelectAll = _.last(event.target.value) === 'all';
    event.target.value = isSelectAll
      ? []
      : event.target.value.filter((i: any) => i !== 'all');
    setSelected(() => event.target.value);
  };
  const handleClose = () => {
    props.onChange(selected);
  };

  return (
    <TextField
      fullWidth
      label="Sections"
      {...props}
      value={!selected?.length ? ['all'] : selected}
      select
      SelectProps={{
        multiple: true,
        onClose: handleClose,
        MenuProps: {
          sx: {maxHeight: '500px'},
        },
      }}
      onChange={handleChange}
    >
      <MenuItem value="all">All Sections</MenuItem>

      {options.map((option) => (
        <MenuItem key={option.id ?? ''} value={option.id ?? ''}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import StarIcon from '@mui/icons-material/Star';
import {Button, Menu, MenuItem, Typography} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {useAppSelector} from '../../hooks/redux';
import {Dashboard} from '../../interfaces/Dashboard';
import AccessControl from '../common/AccessControl';
import ModalFixed from '../common/ModalFixed';
import DashboardItemDeleteModal from './DashboardItemDeleteModal';
import DashboardItemFavoriteModal from './DashboardItemFavouriteModal';
import DashboardItemPrimaryModal from './DashboardItemPrimaryModal';
import DashboardItemUpsert from './DashboardItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: Dashboard;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onDeleted?: () => void;
}

type Action = 'update' | 'primary' | 'favourite' | 'delete';

const DashboardItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onDeleted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<Action>();

  const me = useAppSelector(({app}) => app.me);
  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <Typography
          component={RouterLink}
          sx={{textDecoration: 'none'}}
          color="inherit"
          to={`/dashboards/${item.id}`}
        >
          <MenuItem>
            <OpenInBrowserIcon fontSize="small" sx={{mr: 1.5}} /> Open
          </MenuItem>
        </Typography>

        <AccessControl
          permissions={['patch::/dashboard/:id(\\d+)']}
          accessChecker={() => item.user_id === me?.id}
        >
          <MenuItem
            onClick={() => {
              setAction('update');
              setAnchorEl(null);
            }}
          >
            <EditIcon fontSize="small" sx={{mr: 1.5}} /> Edit
          </MenuItem>
        </AccessControl>

        {!item.is_primary ? (
          <AccessControl permissions={['post::/dashboard/:id(\\d+)/primary']}>
            <MenuItem
              onClick={() => {
                setAction('primary');
                setAnchorEl(null);
              }}
            >
              <BookmarkIcon fontSize="small" sx={{mr: 1.5}} /> Set as Primary
            </MenuItem>
          </AccessControl>
        ) : (
          <AccessControl permissions={['delete::/dashboard/:id(\\d+)/primary']}>
            <MenuItem
              onClick={() => {
                setAction('primary');
                setAnchorEl(null);
              }}
            >
              <BookmarkIcon fontSize="small" sx={{mr: 1.5}} /> Unset as Primary
            </MenuItem>
          </AccessControl>
        )}

        {!item.is_favourite ? (
          <AccessControl
            permissions={['post::/dashboard/:id(\\d+)/favourite']}
            accessChecker={() => item.user_id === me?.id}
          >
            <MenuItem
              onClick={() => {
                setAction('favourite');
                setAnchorEl(null);
              }}
            >
              <StarIcon fontSize="small" sx={{mr: 1.5}} /> Set as Favorite
            </MenuItem>
          </AccessControl>
        ) : (
          <AccessControl
            permissions={['delete::/dashboard/:id(\\d+)/favourite']}
            accessChecker={() => item.user_id === me?.id}
          >
            <MenuItem
              onClick={() => {
                setAction('favourite');
                setAnchorEl(null);
              }}
            >
              <StarIcon fontSize="small" sx={{mr: 1.5}} /> Unset as Favorite
            </MenuItem>
          </AccessControl>
        )}

        <AccessControl
          permissions={['delete::/dashboard/:id(\\d+)']}
          accessChecker={() => item.user_id === me?.id}
        >
          <MenuItem
            onClick={() => {
              setAction('delete');
              setAnchorEl(null);
            }}
          >
            <DeleteIcon fontSize="small" sx={{mr: 1.5}} /> Delete
          </MenuItem>
        </AccessControl>
      </Menu>

      <ModalFixed
        open={action === 'update'}
        onClose={() => setAction(undefined)}
      >
        <DashboardItemUpsert
          pk={item.id}
          item={item}
          onSubmitted={() => {
            onSubmitted?.();
            setAction(undefined);
          }}
          onClose={() => setAction(undefined)}
        />
      </ModalFixed>

      <DashboardItemPrimaryModal
        open={action === 'primary'}
        item={item}
        onSubmitted={onSubmitted}
        onClose={() => setAction(undefined)}
      />

      <DashboardItemFavoriteModal
        open={action === 'favourite'}
        item={item}
        onSubmitted={onSubmitted}
        onClose={() => setAction(undefined)}
      />

      <DashboardItemDeleteModal
        open={action === 'delete'}
        item={item}
        onDeleted={onDeleted}
        onClose={() => setAction(undefined)}
      />
    </>
  );
};

export default DashboardItemActionsButton;

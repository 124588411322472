import PersonIcon from '@mui/icons-material/Person';
import {ListItem, Modal} from '@mui/material';
import {Box} from '@mui/system';
import React, {ReactNode, useState} from 'react';

import {Me} from '../../../interfaces/Me';
import MyAccount from '../MyAccount';

interface Props {
  pk?: number;
  item?: Me;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onSubmitted?: () => void;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflowY: 'auto',
  maxHeight: '100%',
};

const MyAccountButton = ({
  component = ListItem,
  componentProps = {},
  children,
}: Props) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal open={isOpened} onClose={closeModal}>
        <Box sx={style}>
          <Box fontSize={24} display="flex" alignItems="center">
            <PersonIcon sx={{mr: 1}} />
            My Account
          </Box>

          <MyAccount onClose={closeModal} />
        </Box>
      </Modal>
    </>
  );
};

export default MyAccountButton;

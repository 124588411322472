import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Divider, IconButton} from '@mui/material';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {SketchPicker} from 'react-color';

import NumberTextField from '../common/NumberTextField';
import {HeatmapConfig} from '../dashboard-panels/HazardHeatmap/HazardHeatmapGrid';

const popover = {
  position: 'absolute',
  zIndex: '2',
  bottom: '100%',
};
const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
};
interface Props {
  item?: HeatmapConfig | null;
  onSave?: Function;
  onCloseConfigModal?: Function;
}

const HazardHeatmapConfiguration = ({
  item,
  onSave,
  onCloseConfigModal,
}: // onSubmitted,
Props) => {
  const [densityRadius, setDensityRadius] = useState(
    item ? item.density_radius ?? 20 : 20
  );
  const [blur, setBlur] = useState(item ? item.blur : 15);
  const [radius, setRadius] = useState(item ? item.radius : 10);
  const [max, setMax] = useState(item ? item.max : 0.001);
  const [colors, setColors] = useState<
    {
      key: number;
      intensity: number;
      color: string;
    }[]
  >([]);

  useEffect(() => {
    if ((item?.color_gradients?.length ?? 0) < 1) {
      setColors([
        {
          key: 0,
          intensity: 0.4,
          color: 'blue',
        },
        {
          key: 1,
          intensity: 0.6,
          color: 'cyan',
        },
        {
          key: 2,
          intensity: 0.7,
          color: 'lime',
        },
        {
          key: 3,
          intensity: 0.8,
          color: 'yellow',
        },
        {
          key: 4,
          intensity: 1,
          color: 'red',
        },
      ]);
    } else {
      setColors(
        item?.color_gradients?.map((it, index) => ({
          key: index,
          intensity: it.intensity,
          color: it.color,
        })) ?? []
      );
    }
  }, [item?.color_gradients]);

  const [isOpenPicker, setIsOpenPicker] = useState(-1);
  const onClickOpenPicker = (index: number) => {
    setIsOpenPicker(index);
  };

  const saveConfigData = () => {
    onSave?.({
      density_radius: densityRadius,
      radius,
      blur,
      max,
      color_gradients: colors?.map((it) => ({
        intensity: it.intensity,
        color: it?.color,
      })),
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box fontSize={20}>HeatMap Configuration</Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <NumberTextField
          label="Denisty Radius (km)"
          min={0}
          size="small"
          value={densityRadius}
          fullWidth
          onChange={(e) => {
            setDensityRadius(e ?? 0);
          }}
        />
        <NumberTextField
          label="Blur"
          min={0}
          size="small"
          value={blur}
          fullWidth
          onChange={(e) => {
            setBlur(e ?? 0);
          }}
        />
        <NumberTextField
          label="Radius"
          min={0}
          size="small"
          value={radius}
          fullWidth
          onChange={(e) => {
            setRadius(e ?? 0);
          }}
        />
        <NumberTextField
          label="Max"
          min={0}
          decimalPlaces={5}
          step={0.00001}
          size="small"
          value={max}
          fullWidth
          onChange={(e) => {
            setMax(e ?? 0);
          }}
        />
        <Divider />
        <Box display="flex">
          <Box flex="1">
            <Box display="flex" flexWrap="wrap" mb="20px" columnGap="14px">
              <Box width="30px"></Box>
              <Box width="30%">Intensity</Box>
              <Box flex={1}>Color</Box>
            </Box>
            {colors?.map((it, index) => (
              <Box
                key={index}
                display="flex"
                columnGap="14px"
                flexWrap="wrap"
                alignItems="center"
                mb="20px"
              >
                <Box width="30px">
                  <IconButton
                    size="small"
                    onClick={() => {
                      const index = colors?.findIndex(
                        (el) => `${el.key}` === `${it.key}`
                      );
                      if (index > -1) {
                        _.pullAt(colors, index);
                        setColors([...colors]);
                      }
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Box width="30%">
                  <NumberTextField
                    max={1}
                    min={0}
                    value={Number(it.intensity.toFixed(1))}
                    onChange={(e) => {
                      if (!_.isNull(e)) {
                        const cloneObj = _.cloneDeep(colors);
                        cloneObj[index] = {
                          ...cloneObj[index],
                          intensity: e,
                        };
                        setColors(cloneObj);
                      }
                    }}
                    step={0.1}
                    size="small"
                    name="blue"
                    fullWidth
                  />
                </Box>
                <Box flex="1" position="relative">
                  <Box
                    width="36px"
                    height="36px"
                    borderRadius="50%"
                    position="relative"
                    onClick={() => {
                      onClickOpenPicker(index);
                    }}
                    sx={{
                      backgroundColor: it?.color,
                      cursor: 'pointer',
                    }}
                  ></Box>
                  {isOpenPicker > -1 && isOpenPicker === index ? (
                    <Box sx={popover}>
                      <Box
                        sx={cover}
                        onClick={() => {
                          setIsOpenPicker(-1);
                        }}
                      />
                      <SketchPicker
                        color={it.color}
                        onChange={(e) => {
                          if (!_.isNull(e)) {
                            const cloneObj = _.cloneDeep(colors);
                            cloneObj[index] = {
                              ...cloneObj[index],
                              color: e.hex,
                            };
                            setColors(cloneObj);
                          }
                        }}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            ))}
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                const newKey =
                  colors?.length > 0
                    ? (_.maxBy(colors, 'key')?.key ?? 0) + 1
                    : 0;
                if (colors?.length > 0) {
                  setColors([
                    ...colors,
                    {
                      key: newKey,
                      intensity: 1,
                      color: '#f00',
                    },
                  ]);
                } else {
                  setColors([
                    {
                      key: newKey,
                      intensity: 1,
                      color: '#f00',
                    },
                  ]);
                }
              }}
            >
              <AddIcon />
              Add New Row
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" alignItems="center" columnGap="10px">
        <LoadingButton
          variant="contained"
          type="button"
          onClick={() => {
            saveConfigData();
          }}
        >
          Save
        </LoadingButton>
        <Button
          type="button"
          onClick={() => {
            onCloseConfigModal?.();
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default HazardHeatmapConfiguration;

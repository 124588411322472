import {Icon} from 'leaflet';
import mapValues from 'lodash/mapValues';

interface ColoredIcons {
  [k: string]: any;
}

export const COLORED_ICONS = {
  communicationNodes: {
    default: require('../images/communication-nodes/communication-node-default.png'),
    blue: require('../images/communication-nodes/communication-node-blue.png'),
    brown: require('../images/communication-nodes/communication-node-brown.png'),
    cornsilk: require('../images/communication-nodes/communication-node-cornsilk.png'),
    cyan: require('../images/communication-nodes/communication-node-cyan.png'),
    green: require('../images/communication-nodes/communication-node-green.png'),
    dark_blue: require('../images/communication-nodes/communication-node-dark_blue.png'),
    gold: require('../images/communication-nodes/communication-node-gold.png'),
    gray: require('../images/communication-nodes/communication-node-gray.png'),
    orchid: require('../images/communication-nodes/communication-node-orchid.png'),
    pink: require('../images/communication-nodes/communication-node-pink.png'),
    red: require('../images/communication-nodes/communication-node-red.png'),
    yellow: require('../images/communication-nodes/communication-node-yellow.png'),
    orange: require('../images/communication-nodes/communication-node-orange.png'),
    duplicate: require('../images/other/duplicate_icon.png'),
  } as ColoredIcons,
  miners: {
    default: require('../images/miners/miner-default.png'),
    blue: require('../images/miners/miner-blue.png'),
    brown: require('../images/miners/miner-brown.png'),
    cornsilk: require('../images/miners/miner-cornsilk.png'),
    cyan: require('../images/miners/miner-cyan.png'),
    green: require('../images/miners/miner-green.png'),
    dark_blue: require('../images/miners/miner-dark_blue.png'),
    gold: require('../images/miners/miner-gold.png'),
    gray: require('../images/miners/miner-gray.png'),
    orchid: require('../images/miners/miner-orchid.png'),
    pink: require('../images/miners/miner-pink.png'),
    red: require('../images/miners/miner-red.png'),
    yellow: require('../images/miners/miner-yellow.png'),
    orange: require('../images/miners/miner-orange.png'),
  } as ColoredIcons,
  assetEvents: {
    default: require('../images/asset-events/asset-event-default.png'),
    blue: require('../images/asset-events/asset-event-blue.png'),
    brown: require('../images/asset-events/asset-event-brown.png'),
    cornsilk: require('../images/asset-events/asset-event-cornsilk.png'),
    cyan: require('../images/asset-events/asset-event-cyan.png'),
    green: require('../images/asset-events/asset-event-green.png'),
    dark_blue: require('../images/asset-events/asset-event-dark_blue.png'),
    gold: require('../images/asset-events/asset-event-gold.png'),
    gray: require('../images/asset-events/asset-event-gray.png'),
    orchid: require('../images/asset-events/asset-event-orchid.png'),
    pink: require('../images/asset-events/asset-event-pink.png'),
    red: require('../images/asset-events/asset-event-red.png'),
    yellow: require('../images/asset-events/asset-event-yellow.png'),
    orange: require('../images/asset-events/asset-event-orange.png'),
  } as ColoredIcons,
  assets: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  fans: {
    healthy: require('../images/fans/fan-healthy.png'),
    blue: require('../images/fans/fan-blue.png'),
    brown: require('../images/fans/fan-brown.png'),
    cornsilk: require('../images/fans/fan-cornsilk.png'),
    cyan: require('../images/fans/fan-cyan.png'),
    green: require('../images/fans/fan-green.png'),
    dark_blue: require('../images/fans/fan-dark_blue.png'),
    gold: require('../images/fans/fan-gold.png'),
    gray: require('../images/fans/fan-gray.png'),
    orchid: require('../images/fans/fan-orchid.png'),
    pink: require('../images/fans/fan-pink.png'),
    red: require('../images/fans/fan-red.png'),
    yellow: require('../images/fans/fan-yellow.png'),
    orange: require('../images/fans/fan-orange.png'),
  } as ColoredIcons,
  belts: {
    healthy: require('../images/belts/belt-healthy.png'),
    blue: require('../images/belts/belt-blue.png'),
    brown: require('../images/belts/belt-brown.png'),
    cornsilk: require('../images/belts/belt-cornsilk.png'),
    cyan: require('../images/belts/belt-cyan.png'),
    green: require('../images/belts/belt-green.png'),
    dark_blue: require('../images/belts/belt-dark_blue.png'),
    gold: require('../images/belts/belt-gold.png'),
    gray: require('../images/belts/belt-gray.png'),
    orchid: require('../images/belts/belt-orchid.png'),
    pink: require('../images/belts/belt-pink.png'),
    red: require('../images/belts/belt-red.png'),
    yellow: require('../images/belts/belt-yellow.png'),
    orange: require('../images/belts/belt-orange.png'),
  } as ColoredIcons,
  amsUnknown: {
    health: require('../images/ams/unknown/ams-unknown-health.png'),
    blue: require('../images/ams/unknown/ams-unknown-blue.png'),
    brown: require('../images/ams/unknown/ams-unknown-brown.png'),
    cornsilk: require('../images/ams/unknown/ams-unknown-cornsilk.png'),
    cyan: require('../images/ams/unknown/ams-unknown-cyan.png'),
    green: require('../images/ams/unknown/ams-unknown-green.png'),
    dark_blue: require('../images/ams/unknown/ams-unknown-dark_blue.png'),
    gold: require('../images/ams/unknown/ams-unknown-gold.png'),
    gray: require('../images/ams/unknown/ams-unknown-gray.png'),
    orchid: require('../images/ams/unknown/ams-unknown-orchid.png'),
    pink: require('../images/ams/unknown/ams-unknown-pink.png'),
    red: require('../images/ams/unknown/ams-unknown-red.png'),
    yellow: require('../images/ams/unknown/ams-unknown-yellow.png'),
    orange: require('../images/ams/unknown/ams-unknown-orange.png'),
  } as ColoredIcons,
  wifi: {
    blue: require('../images/wifi/wifi-blue.png'),
    red: require('../images/wifi/wifi-red.png'),
    gray: require('../images/wifi/wifi-gray.png'),
  } as ColoredIcons,
  alarm: {
    blue: require('../images/alarms/alarm-blue.png'),
    red: require('../images/alarms/alarm-red.png'),
    gray: require('../images/alarms/alarm-gray.png'),
    yellow: require('../images/alarms/alarm-yellow.png'),
    brown: require('../images/alarms/alarm-brown.png'),
    default: require('../images/alarms/alarm-default.png'),
    cornsilk: require('../images/alarms/alarm-cornsilk.png'),
    cyan: require('../images/alarms/alarm-cyan.png'),
    green: require('../images/alarms/alarm-green.png'),
    dark_blue: require('../images/alarms/alarm-dark_blue.png'),
    gold: require('../images/alarms/alarm-gold.png'),
    orchid: require('../images/alarms/alarm-orchid.png'),
    pink: require('../images/alarms/alarm-pink.png'),
    orange: require('../images/alarms/alarm-orange.png'),
  } as ColoredIcons,
  safeyeNodes: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  vision: {
    blue: require('../images/other/camera-blue.png'),
    yellow: require('../images/other/camera-yellow.png'),
    red: require('../images/other/camera-red.png'),
  } as ColoredIcons,
  person: {
    blue: require('../images/other/user-blue.png'),
    yellow: require('../images/other/user-yellow.png'),
    red: require('../images/other/user-red.png'),
  } as ColoredIcons,
};

export const COLORED_LEAFLET_ICONS = mapValues(COLORED_ICONS, (i) =>
  mapValues(
    i,
    (iconUrl) =>
      new Icon({
        iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      })
  )
);

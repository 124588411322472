import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  MachineHistoryReportItem,
  MachineHistoryReportQuery,
  MachineHistoryReportResponse,
} from '../../../interfaces/MachineHistoryReport';
import reduxSelectors from '../../../redux/selectors';
import {getBooleanValue} from '../../../utils/boolean';
import {saveFile} from '../../../utils/file';
import {isPresent} from '../../../utils/type-guards';
import AssetMachineSelect from '../../asset-machine/AssetMachineSelect';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {
  DataGridColumn,
  DataGridRef,
  defaultValueFormatter,
  RenderCellParams,
} from '../../common/DataGrid';
import TruncatedText from '../../common/TruncantedText';
import {
  usePanel,
  usePanelFilter,
} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {HumanMachineZoneSelect} from '../../selectors/HumanMachineZoneSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ShiftSelect} from '../../selectors/ShiftSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {ZoneChangeCheckbox} from './ZoneChangeCheckbox';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'machine_name',
  'zone',
  'event_time',
  'gen1',
  'gen2',
  'gen3',
  'gen4',
  'maintenance',
  'comms',
];

export interface MachineHistoryReportData {
  refreshInterval?: number | null;
  params?: {
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
    external_id?: number | null;
    shift_id?: number | null;
    date_start?: string | null;
    date_end?: string | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
    alert_type?: number[] | null;
    zone_change?: 0 | 1 | null;
  };
}

export const getMachineHistoryReportData = (): MachineHistoryReportData => ({
  params: {
    zone_change: 1,
  },
});

const getGenValue = (v?: string | null) => {
  if (v === 'yes') {
    return 'low';
  } else if (v === 'no') {
    return 'ok';
  }
  return v;
};

const renderGenCell = (
  params: RenderCellParams<MachineHistoryReportItem>,
  title: string
) => {
  const formattedValue = defaultValueFormatter(params);
  return (
    <Tooltip
      title={
        <Box fontSize={14}>
          {title}:{' '}
          <Box component="span" fontWeight={600}>
            {formattedValue}
          </Box>
        </Box>
      }
    >
      <Box>
        <TruncatedText
          title={formattedValue?.toString()}
          sx={{
            '@media print': {
              whiteSpace: 'normal',
            },
          }}
        >
          {formattedValue}
        </TruncatedText>
      </Box>
    </Tooltip>
  );
};

const MachineHistoryReport = (props: Props) => {
  const [panel] = usePanel();
  const {filter: config, setFilter: setConfig} = usePanelFilter();

  const assetMachines = useAppSelector(({assets}) =>
    assets.asset_machines.filter((i) => i.status === 'active' && i.external_id)
  );

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<MachineHistoryReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<MachineHistoryReportQuery>(
    () => ({
      page: config.params?.page ?? props.value?.params?.page ?? 0,
      limit: config.params?.limit ?? props.value?.params?.limit ?? 25,
      order: config.params?.order ?? props.value?.params?.order ?? null,
      dir: config.params?.dir ?? props.value?.params?.dir ?? null,
      external_id:
        config.params?.external_id ?? props.value?.params?.external_id ?? null,
      shift_id:
        config.params?.shift_id ?? props.value?.params?.shift_id ?? null,
      date_start:
        config.params?.date_start ??
        props.value?.params?.date_start ??
        dayjs().format('YYYY-MM-DD'),
      date_end:
        config.params?.date_end ??
        props.value?.params?.date_end ??
        dayjs().format('YYYY-MM-DD'),
      input: config.params?.input ?? props.value?.params?.input ?? {},
      alert_type:
        config.params?.alert_type ?? props.value?.params?.alert_type ?? null,
      zone_change:
        config.params?.zone_change ?? props.value?.params?.zone_change ?? null,
    }),
    [config]
  );

  const fetchData = useCallback(
    async (params: MachineHistoryReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/machine-history`;
        const resp = await API.get<MachineHistoryReportResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  const selectedAssetMachine = useMemo(
    () =>
      params.external_id
        ? assetMachines.find((i) => i.external_id === params.external_id)
        : null,
    [params.external_id]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...config, params});
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const machineInputs = useAppSelector(({assets}) =>
    assets.machine_inputs.filter((i) => i.status === 'active')
  );
  const [shownFields, setShownFields] = useState([
    ...DEFAULT_SHOWN_FIELDS,
    ...machineInputs.map((i) => `input_${i.id}`),
  ]);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<MachineHistoryReportItem>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'machine_name',
      headerName: 'Machine',
      sortable: true,
    },
    {
      field: 'zone',
      headerName: 'Alert Type',
      sortable: true,
    },
    {
      field: 'event_time',
      headerName: 'Event Time',
      sortable: true,
    },
    {
      field: 'gen1',
      headerName: 'G1',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen1),
      renderCell: (params) => renderGenCell(params, 'Generator #1 Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen2',
      headerName: 'G2',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen2),
      renderCell: (params) => renderGenCell(params, 'Generator #2 Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen3',
      headerName: 'G3',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen3),
      renderCell: (params) => renderGenCell(params, 'Generator #3 Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen4',
      headerName: 'G4',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen4),
      renderCell: (params) => renderGenCell(params, 'Generator #4 Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    ...machineInputs.map((i) => ({
      field: `input_${i.id}`,
      headerName: i.name,
      sortable: false,
      valueGetter: (params: any) => {
        const v = params.row[`input_${i.id}`];
        return getBooleanValue(v, 'On', 'Off');
      },
    })),
    {
      field: 'maintenance',
      headerName: 'Maintenance',
      sortable: true,
    },
    {
      field: 'comms',
      headerName: 'Comms',
      sortable: true,
    },
    {
      field: 'gen1_comm',
      headerName: 'G1 Comm',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen1_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #1 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen2_comm',
      headerName: 'G2 Comm',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen2_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #2 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen3_comm',
      headerName: 'G3 Comm',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen3_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #3 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen4_comm',
      headerName: 'G4 Comm',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen4_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #4 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen1_lowvoltage',
      headerName: 'FV1',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen1_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #1 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen2_lowvoltage',
      headerName: 'FV2',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen2_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #2 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen3_lowvoltage',
      headerName: 'FV3',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen3_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #3 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen4_lowvoltage',
      headerName: 'FV4',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen4_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #4 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
  ];

  /*********/
  /* export */
  /*********/
  const [exportInProgress, setExportInProgress] = useState(false);

  const {shifts} = useAppSelector(reduxSelectors.assets.getAssets);

  const submitExport = async (params: MachineHistoryReportQuery) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/machine-history-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
        },
        responseType: 'blob',
      });

      const fileNameParts = [
        'reportMachinesHistory',
        `${params.date_start}__${params.date_end}`,
        shifts.find((i) => i.id === params.shift_id)?.name?.replace(' ', '-'),
        selectedAssetMachine?.name?.replace(' ', '-'),
        machineInputs
          .map((i) => {
            const v = params.input?.[i.id];
            if (v === 1) {
              return `${i.name.replace(' ', '-')}-is-on`;
            } else if (v === 0) {
              return `${i.name.replace(' ', '-')}-is-off`;
            }
          })
          .filter(isPresent)
          .join('_'),
      ].filter(isPresent);

      const fileName = `${fileNameParts.join('__')}.csv`;

      saveFile(resp.data, fileName);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setExportInProgress(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="top"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(params.date_start).toDate(),
                  dayjs(params.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...params,
                          date_start: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                          date_end: v?.[0]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <AssetMachineSelect
              value={selectedAssetMachine?.id}
              size="small"
              label="Machine"
              nullLabel="All Machines"
              assetMachines={assetMachines}
              sx={{minWidth: 200}}
              onChange={(_, item) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...params,
                        external_id: item?.external_id,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />

            <ZoneChangeCheckbox
              value={!!params.zone_change}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...params,
                        zone_change: v ? 1 : 0,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />

            <ShiftSelect
              value={params.shift_id}
              size="small"
              nullLabel="All Shifts"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...params,
                        shift_id: v,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />

            <Box minWidth={200}>
              <MachineInputSelectV2
                value={params.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...params,
                          input: v,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <HumanMachineZoneSelect
              value={{
                machine: params.alert_type ?? null,
              }}
              showHuman={false}
              size="small"
              label="Zones"
              sx={{minWidth: 200}}
              onChangeMachine={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...params,
                        alert_type: v,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />
          </Box>

          <Box display="flex" py={1}>
            <ButtonGroup size="small" sx={{height: 40}}>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  loading={fetchedInProgress}
                  variant="outlined"
                  onClick={() => params && fetchData(params)}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport(params)}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => dataGridRef.current?.printTable()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={
          params?.order
            ? {
                field: params?.order,
                dir: params?.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="server"
        page={params?.page}
        pageSize={params?.limit}
        rowCount={fetchedData?.total}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        getSxTr={(row) => {
          if (
            row.gen1 === 'fail' ||
            row.gen2 === 'fail' ||
            row.gen3 === 'fail' ||
            row.gen4 === 'fail' ||
            row.gen1_lowvoltage === 'low' ||
            row.gen2_lowvoltage === 'low' ||
            row.gen3_lowvoltage === 'low' ||
            row.gen4_lowvoltage === 'low'
          ) {
            return (theme) => ({
              color: theme.palette.error.main,
              bgcolor:
                theme.palette.mode === 'dark'
                  ? undefined
                  : '#febfbe !important',
            });
          }
        }}
        onPageChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: v,
                },
              },
            })
          );
        }}
        onPageSizeChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: 0,
                  limit: v,
                },
              },
            })
          );
        }}
        onSort={(v) => {
          if (v) {
            setConfig?.(
              update(config, {
                params: {
                  $set: {
                    ...config.params,
                    order: v.field,
                    dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                  },
                },
              })
            );
          }
        }}
        onShownFieldsChange={setShownFields}
      />
    </Box>
  );
};

export default MachineHistoryReport;

import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, IconButton, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {useRefreshInterval} from '../../hooks/refreshInterval';
import {
  AssetHumanGroupMessageListQueryParams,
  AssetHumanGroupMessageListResponse,
} from '../../interfaces/AssetHumanGroupMessage';
import AccessControl from '../common/AccessControl';
import {AutoRefreshSelect} from '../common/AutoRefreshSelect';
import {DateRangeSelect} from '../selectors/DateRangeSelect';
import AssetHumanGroupMessageClearModal from './AssetHumanGroupMessageClearModal';
import {AssetHumanGroupSelect} from './AssetHumanGroupSelect';

interface Props {
  groupId?: number | null;
  onChangeGroupId?: (value: number | null) => void;
}

export interface AssetHumanGroupMessageListRef {
  fetch: () => void;
}

const AssetHumanGroupMessageList = forwardRef<
  AssetHumanGroupMessageListRef,
  Props
>(({groupId, onChangeGroupId}, ref) => {
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
  }));

  const chatRef = useRef<HTMLDivElement>();

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<AssetHumanGroupMessageListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: AssetHumanGroupMessageListQueryParams) => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<AssetHumanGroupMessageListResponse>(
        `${apiBaseUrl}/group-message`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
      }
    }, 100);
    setFetchedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = () => ({
    id: groupId ?? null,
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  });

  const formik = useFormik<AssetHumanGroupMessageListQueryParams>({
    initialValues: getFormikValues(),
    onSubmit: (values) => {
      fetchData(values);
    },
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.id !== groupId) {
      formik.setFieldValue('id', groupId);
    }
  }, [groupId]);

  const [isShownClearModal, setIsShownClearModal] = useState(false);

  const assetHumanGroups = useAppSelector(
    ({assets}) => assets.asset_human_groups
  );
  const getGroupNameByid = useCallback(
    (id: number | null) => {
      if (!id) {
        return 'Non-Emergency Broadcast';
      }
      return (
        assetHumanGroups.find((group) => group.id === id)?.name ??
        'Unknown Group'
      );
    },
    [assetHumanGroups]
  );

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    formik.handleSubmit,
    30000
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2}>
        <AssetHumanGroupSelect
          value={groupId}
          nullLabel="All Groups"
          size="small"
          disableClearable
          sx={{width: 150}}
          onChange={(v) => onChangeGroupId?.(v ?? null)}
        />
        <Box maxWidth={330}>
          <DateRangeSelect
            value={[
              dayjs(formik.values.date_start).toDate(),
              dayjs(formik.values.date_end).toDate(),
            ]}
            size="small"
            onChange={(value) => {
              formik.setFieldValue(
                'date_start',
                dayjs(value[0] ?? undefined).format('YYYY-MM-DD')
              );
              formik.setFieldValue(
                'date_end',
                dayjs(value[1] ?? undefined).format('YYYY-MM-DD')
              );
            }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title="Refresh">
            <LoadingButton
              color="primary"
              loading={fetchedInProgress}
              sx={{p: 0.5, minWidth: 0}}
              onClick={() => fetchData(formik.values)}
            >
              <RefreshIcon />
            </LoadingButton>
          </Tooltip>
          <AutoRefreshSelect
            value={refreshInterval ?? null}
            variant="text"
            onChange={(v) => setRefreshInterval(v)}
          />
          <AccessControl permissions={['delete::/group-message']}>
            <Tooltip title="Clear History">
              <IconButton
                color="error"
                onClick={() => setIsShownClearModal(true)}
              >
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          </AccessControl>
        </Box>
      </Box>
      <Box>
        {fetchedErrors.map((error, idx) => (
          <Alert key={idx} severity="error">
            {error}
          </Alert>
        ))}
      </Box>
      <Box
        ref={chatRef}
        p={2}
        border={1}
        position="relative"
        height={350}
        overflow="auto"
      >
        {fetchedData?.items.length ? (
          <Box width="100%" display="flex" flexDirection="column" gap={2}>
            {fetchedData?.items.map((item, idx) => (
              <Box
                key={idx}
                display="flex"
                flexDirection="column"
                alignItems="end"
                gap={0.5}
              >
                <Box fontSize={12} color="">
                  {item.src_name} {'->'} {getGroupNameByid(item.group_id)} (
                  {item.date})
                </Box>
                <Box
                  bgcolor="info.main"
                  color="info.contrastText"
                  p={1}
                  borderRadius={1}
                  display="inline-flex"
                >
                  {item.message}
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {fetchedInProgress ? 'Loading...' : 'No messages'}
          </Box>
        )}
      </Box>

      <AssetHumanGroupMessageClearModal
        groupId={groupId}
        open={isShownClearModal}
        onSubmitted={() => {
          setIsShownClearModal(false);
          fetchData(formik.values);
        }}
        onClose={() => setIsShownClearModal(false)}
      />
    </Box>
  );
});

export default AssetHumanGroupMessageList;

import {Box, BoxProps, Tooltip} from '@mui/material';
import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import reduxSelectors from '../../redux/selectors';
import {connectionStatusIcons} from '../../utils/event-icons';

interface Props extends BoxProps {
  short?: boolean;
}

const Status = ({short = false, ...props}: Props) => {
  const app = useAppSelector(reduxSelectors.app.getApp);

  const backend = useMemo(() => {
    return app.backend;
  }, [app.backend]);

  const version = useMemo(() => backend?.version, [backend?.version]);

  if (short) {
    return (
      <Box {...props} sx={{display: 'inline-flex', padding: '3px'}}>
        <Tooltip
          title={`response: ${backend?.delay}ms, ${backend?.connection_status}\nfrontend: ${
            process.env.REACT_APP_VERSION ?? 'unknown'
          }, backend: ${version ?? ''}`}
          placement="top-end"
        >
          {connectionStatusIcons[backend?.connection_status ?? 'disconnected']}
        </Tooltip>
      </Box>
    );
  } else {
    return (
      <Box {...props} sx={{display: 'inline-flex', padding: '3px'}}>
        <Tooltip
          title={`response: ${backend?.delay}ms, ${backend?.connection_status}`}
          placement="top-end"
        >
          {connectionStatusIcons[backend?.connection_status ?? 'disconnected']}
        </Tooltip>
        <Tooltip
          title={`frontend version: ${process.env.REACT_APP_VERSION ?? 'unknown'}, backend version: ${version ?? ''}`}
          placement="top-end"
        >
          <Box
            sx={{margin: '0 0 0 8px'}}
          >{`f:${process.env.REACT_APP_VERSION ?? 'unknown'}, b:${version ?? ''}`}</Box>
        </Tooltip>
      </Box>
    );
  }
};

export default Status;

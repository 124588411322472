import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { LoadingButton } from "@mui/lab";
import { Alert, Backdrop, Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { t } from "i18next";
import _ from "lodash";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";

import API from "../../../api/axios";
import { apiBaseUrl } from "../../../api/urls";
import { useRefreshInterval } from "../../../hooks/refreshInterval";
import { DashboardPanelData } from "../../../interfaces/Dashboard";
import { MqttStatusResponse } from "../../../interfaces/Mqtt";
import { SystemService, SystemStatusResponse } from "../../../interfaces/SystemStatus";
import AccessControl from "../../common/AccessControl";
import { AutoRefreshSelect } from "../../common/AutoRefreshSelect";
import { CloseSnackbarAction } from "../../common/CloseSnackbarButton";
import { TableColumn } from "../../common/TableColumnActionsButton";
import { usePanel } from "../../dashboards/entities/DashboardEntityContext";
import { DashboardPanelTitleSlot } from "../DashboardPanelTitleSlot";

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

type SystemServiceTableColumnField = TableColumn<keyof SystemService>;

export const SystemServicesTable = (_props: Props) => {
  const [panel] = usePanel();

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<SystemStatusResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const [statusErrors, setStatusErrors] = useState<string[]>([]);
  const [statusProgress, setStatusProgress] = useState<string[]>([]);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      setFetchedErrors([]);
      const resp = await API.get<SystemStatusResponse>(
        `${apiBaseUrl}/system/status`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.response.data?.errors ?? []);
    }

    setFetchedInProgress(false);
  };

  const submitStatus = async (action: string, name: string) => {
    statusProgress.push(name);
    setStatusProgress(statusProgress);
    setStatusErrors([]);
    try {
      const resp = await API.patch<MqttStatusResponse>(`${apiBaseUrl}/${action}`);
      enqueueSnackbar(`${resp.data.message}`, {
        variant: "success",
        action: CloseSnackbarAction,
      });
      fetchData();
    } catch (error: any) {
      setStatusErrors(error.response.data?.errors ?? []);
    }

    const index = statusProgress.indexOf(name);
    setStatusProgress(statusProgress.splice(index, 1));
  };

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(fetchData, null);

  /*************/
  /* data grid */
  /*************/
  const columns = useMemo<SystemServiceTableColumnField[]>(
    () => [
      { field: "name", headerName: "Label" },
      { field: "short_id", headerName: "ID" },
      {
        field: 'status',
        headerName: 'Status',
        valueGetter: (param: any) =>
          param?.row?.running ? (
            <ToggleOnIcon color="success" />
          ) : (
            <ToggleOffIcon />
          ),
      },
      {
        field: "action_slug",
        headerName: "Action",
        valueGetter: (param: any) =>
          param?.row?.action_slug ? (
            <AccessControl permissions={[param?.row?.action_acl]}>
              <LoadingButton
                variant="outlined"
                startIcon={
                  param?.row?.action_type === "restart" ? (
                    <PlayCircleOutlineIcon />
                  ) : param?.row?.running ? (
                    <DoDisturbOnOutlinedIcon />
                  ) : (
                    <PlayCircleOutlineIcon />
                  )
                }
                loading={statusProgress.indexOf(param?.row?.name) !== -1}
                sx={{ width: 110 }}
                onClick={() => submitStatus(param?.row?.action_slug, param?.row?.name)}
              >
                {param?.row?.action_type === "restart" ? "Restart" : param?.row?.running ? "Stop" : "Start"}
              </LoadingButton>
            </AccessControl>
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  useEffect(() => {
    setRefreshInterval(10000);
    fetchData();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Box display="flex" gap={2} py={1} justifyContent="space-between">
        <Box display="flex" gap={1} py={1}>
          <AutoRefreshSelect
            value={refreshInterval ?? null}
            size="small"
            variant="outlined"
            onChange={(value) => setRefreshInterval(value)}
          />
        </Box>
        <Box display="flex" gap={2}>
          <IconButton disabled={fetchedInProgress} onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <Box height="100%" overflow="scroll">
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error" sx={{mb: 2}}>
            {error}
          </Alert>
        ))}
        {statusErrors.map((error, idx) => (
          <Alert key={`status-error-${idx}`} severity="error" onClose={() => {}}>
            {error}
          </Alert>
        ))}

        <Table sx={{minWidth: 650}}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{
                    minWidth: column.width,
                    whiteSpace: 'nowrap',
                    '.actions': {opacity: 0, mr: -1.5},
                    '&:hover .actions': {opacity: 1},
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Box fontWeight={700} fontSize={16}>
                        {column.headerName ??
                          _.capitalize(column.field.replaceAll('_', ' '))}
                      </Box>
                    </Box>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{overflow: 'auto'}}>
            {fetchedData?.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.field}>
                    {column.valueGetter
                      ? column.valueGetter({row})
                      : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {!fetchedData?.length && (
          <Box
            height="300px"
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {fetchedInProgress ? 'Loading...' : 'No data'}
          </Box>
        )}
      </Box>
    </Box>
  );
};

import {DivIcon} from 'leaflet';
import _ from 'lodash';
import {Marker, Popup, Tooltip} from 'react-leaflet';

import {useConfigurations} from '../../../hooks/configuration';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {Node} from '../../../interfaces/Node';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_TYPES,
  MINER_EVENT_COLORS,
  MINER_EVENT_TYPES,
} from '../../../utils/commtrac-nodes';
import {COLORED_LEAFLET_ICONS} from '../../../utils/icons';
import {CN_EVENT_COLORS, CN_EVENT_TYPES} from '../../../utils/nodes';
import CommtracNodeTooltipContent from './CommtracNodeTooltipContent';
import NodeTooltipContent from './NodeTooltipContent';
import TempItemTooltipContent from './TempItemTooltipContent';
const duplicateIcon = require('../../../images/other/duplicate_icon.png');

interface Props {
  type: 'employee' | 'asset' | 'node';
  topRightPosition?: any | null;
  i: {
    key: string;
    items: any[];
  };
  disableEditMode?: boolean;
  onOpenItem?: (
    id: number,
    type: 'cn' | 'wifi' | 'employee' | 'asset' | 'alarm'
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const TempItemMarker = ({
  type,
  topRightPosition,
  i,
  disableEditMode,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const configurations = useConfigurations();
  const getEmployeeIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_209) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MINER_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_201) {
      color =
        configurations[MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_ALARM] ?? '']
          ?.value;
    } else if (item.e_221) {
      color =
        configurations[
          MINER_EVENT_COLORS[
            MINER_EVENT_TYPES.MINER_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_202) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_226) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_203) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_LOW_BATTERY] ?? ''
        ]?.value;
    } else if (item.e_204) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MESSAGE] ?? ''
        ]?.value;
    } else if (item.e_233) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NOT_MOVING_STATE] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.miners[color ?? 'default'];
  };

  const getAssetIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_309) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_321) {
      color =
        configurations[
          ASSET_EVENT_COLORS[
            ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_301) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_326) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_302) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_LOW_BATTERY] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.assetEvents[color ?? 'default'];
  };

  const getCommunicationNodeIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.e_109) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NEW_CN_DETECTED] ?? '']
          ?.value;
    } else if (item.status === 'inactive') {
      color = 'gray';
    } else if (item.e_101) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_ASSIGNED_MASTER] ?? '']
          ?.value;
    } else if (item.e_121) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS] ?? ''
        ]?.value;
    } else if (item.e_110) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_111) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_102) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NO_HEARTBEAT] ?? '']
          ?.value;
    } else if (item.e_103) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_LOW_BATTERY] ?? '']
          ?.value;
    } else if (item.e_104) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_FEW_HEARTBEATS] ?? '']
          ?.value;
    }

    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.communicationNodes[color ?? 'default'];
  };

  const getWifiIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.wifi_connection_status === 'inactive') {
      color = 'gray';
    } else if (item.wifi_connection_status === 'disconnected') {
      color = 'red';
    }
    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.wifi[color ?? 'blue'];
  };

  const getNodeIcon = (
    item: Node,
    isUnAck: boolean = false,
    isDuplicatedMode: boolean = false
  ) => {
    if (isDuplicatedMode === true) {
      return COLORED_LEAFLET_ICONS.communicationNodes.duplicate;
    }
    if (item.node_type === 'communication_node') {
      return getCommunicationNodeIcon(item, isUnAck);
    } else if (item.node_type === 'wifi_point') {
      return getWifiIcon(item, isUnAck);
    }
    return COLORED_LEAFLET_ICONS.communicationNodes.default;
  };

  return (
    <Marker
      position={
        i.items[0]?.item_type === 'new_devices'
          ? topRightPosition
            ? topRightPosition
            : [0, 0]
          : i.items[0]?.item_type === 'employee' ||
              i.items[0]?.item_type === 'asset'
            ? [
                i.items[0].commtrac_pos_lat ?? 0,
                i.items[0].commtrac_pos_lon ?? 0,
              ]
            : [i.items[0].latitude ?? 0, i.items[0].longitude ?? 0]
      }
      icon={
        i.items.length === 1
          ? type === 'employee'
            ? getEmployeeIcon(i.items[0])
            : type === 'asset'
              ? getAssetIcon(i.items[0])
              : getNodeIcon(i.items[0], i.items[0].item_type === 'new_devices')
          : new DivIcon({
              className: 'duplicate icon',
              html: `<img src="${duplicateIcon}" /><span style="color: #FFF">${i.items.length}</span>`,
              iconAnchor: [16, 37],
            })
      }
      interactive
      eventHandlers={{
        click: (event) => event.target.openPopup(),
        popupopen: (event) => {
          event.target._tooltip.options.opacity = 0;
        },
        popupclose: (event) => {
          event.target._tooltip.options.opacity = 0.9;
        },
      }}
    >
      <Popup interactive autoClose={false} offset={[0, -32]}>
        {i.items?.length === 1 ? (
          <>
            {i.items[0]?.item_type === 'node' ? (
              <NodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (!_.isEmpty(item)) {
                    if (item.node_type === 'communication_node' && item.cn_id) {
                      onOpenItem?.(item.cn_id, 'cn');
                    } else if (
                      item.node_type === 'wifi_point' &&
                      item.wifi_id
                    ) {
                      onOpenItem?.(item.wifi_id, 'wifi');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_id
                    ) {
                      onOpenItem?.(i.items[0].cn_id, 'cn');
                    } else if (
                      i.items[0].node_type === 'wifi_point' &&
                      i.items[0].wifi_id
                    ) {
                      onOpenItem?.(i.items[0].wifi_id, 'wifi');
                    }
                  }
                }}
                onOpenHistory={(item) => {
                  if (!_.isEmpty(item)) {
                    if (
                      item.node_type === 'communication_node' &&
                      item.cn_external_id
                    ) {
                      onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_external_id
                    ) {
                      onOpenHistory?.(
                        i.items[0].cn_external_id,
                        'commtracNodeByCn'
                      );
                    }
                  }
                }}
              />
            ) : (
              <CommtracNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (item) {
                    onOpenItem?.(
                      item?.employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  } else {
                    onOpenItem?.(
                      i.items[0].employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            <TempItemTooltipContent items={i.items}></TempItemTooltipContent>
          </>
        )}
      </Popup>

      <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
        {i.items?.length === 1 ? (
          <>
            {i.items[0]?.item_type === 'node' ? (
              <NodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (!_.isEmpty(item)) {
                    if (item.node_type === 'communication_node' && item.cn_id) {
                      onOpenItem?.(item.cn_id, 'cn');
                    } else if (
                      item.node_type === 'wifi_point' &&
                      item.wifi_id
                    ) {
                      onOpenItem?.(item.wifi_id, 'wifi');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_id
                    ) {
                      onOpenItem?.(i.items[0].cn_id, 'cn');
                    } else if (
                      i.items[0].node_type === 'wifi_point' &&
                      i.items[0].wifi_id
                    ) {
                      onOpenItem?.(i.items[0].wifi_id, 'wifi');
                    }
                  }
                }}
                onOpenHistory={(item) => {
                  if (!_.isEmpty(item)) {
                    if (
                      item.node_type === 'communication_node' &&
                      item.cn_external_id
                    ) {
                      onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_external_id
                    ) {
                      onOpenHistory?.(
                        i.items[0].cn_external_id,
                        'commtracNodeByCn'
                      );
                    }
                  }
                }}
              />
            ) : (
              <CommtracNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (item) {
                    onOpenItem?.(
                      item?.employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  } else {
                    onOpenItem?.(
                      i.items[0].employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            <TempItemTooltipContent items={i.items}></TempItemTooltipContent>
          </>
        )}
      </Tooltip>
    </Marker>
  );
};

export default TempItemMarker;

import {createSelector} from 'reselect';

import {PANEL_CODES, PanelProductCode, PANELS_DATA} from '../../utils/panels';
import {ReduxState} from '../store';
import {AssetsState} from './types';

const assetsSelectors = {
  getAssets: createSelector(
    [(state: any) => state.assets],
    (assets: AssetsState) => assets
  ),

  productsEnabled: createSelector(
    [(state: any) => state.assets],
    (assets: AssetsState) => {
      const company = assets.company;
      const panelProductCodes: PanelProductCode[] = ['core'];

      if (company?.commtrac_enabled) {
        panelProductCodes.push('connect');
      }

      if (company?.wifi_enabled) {
        panelProductCodes.push('wifi');
      }

      if (company?.ams_enabled) {
        panelProductCodes.push('ams');
      }

      if (company?.proximity_enabled) {
        panelProductCodes.push('proximity');
      }

      if (company?.belt_enabled) {
        panelProductCodes.push('belts');
      }

      if (company?.shaft_enabled) {
        panelProductCodes.push('shaft_clearance');
      }

      if (company?.ventilation_enabled) {
        panelProductCodes.push('ventilation');
      }

      if (company?.hazard_ai_enabled) {
        panelProductCodes.push('hazard');
      }

      return panelProductCodes;
    }
  ),

  humans: createSelector(
    [(state: ReduxState) => state.assets],
    (assets: AssetsState) => assets.asset_humans
  ),

  machines: createSelector(
    [(state: ReduxState) => state.assets],
    (assets: AssetsState) => assets.asset_machines
  ),

  machine_inputs: createSelector(
    [(state: ReduxState) => state.assets],
    (assets: AssetsState) => assets.machine_inputs
  ),
};

export const panelCodesEnabledSelect = createSelector(
  [assetsSelectors.productsEnabled],
  (v) => {
    return PANEL_CODES.filter((i) =>
      PANELS_DATA[i].products.find((c) => v.includes(c))
    );
  }
);

export const eventBaseTypesSelect = createSelector(
  [assetsSelectors.getAssets],
  (v) => {
    return v.eventBaseTypes ?? [];
  }
);

export const humanExternalIDs = createSelector(
  [assetsSelectors.humans],
  (humans) => {
    return !humans ? [] : humans.map((h) => h.external_id).filter((h) => !!h);
  }
);

export const humanCommtracExternalIDs = createSelector(
  [assetsSelectors.humans],
  (humans) => {
    return !humans
      ? []
      : humans.map((h) => h.commtrac_external_id).filter((h) => !!h);
  }
);

export const humanMacAddress = createSelector(
  [assetsSelectors.humans],
  (humans) => {
    return !humans ? [] : humans.map((h) => h.mac_address).filter((h) => !!h);
  }
);

export const machineExternalIDs = createSelector(
  [assetsSelectors.machines],
  (machines) => {
    return !machines
      ? []
      : machines.map((m) => m.external_id).filter((m) => !!m);
  }
);

export const machineCommtracExternalIDs = createSelector(
  [assetsSelectors.machines],
  (machines) => {
    return !machines
      ? []
      : machines.map((m) => m.commtrac_external_id).filter((m) => !!m);
  }
);

export const machineMacAddress = createSelector(
  [assetsSelectors.machines],
  (machines) => {
    return !machines
      ? []
      : machines.map((m) => m.mac_address).filter((m) => !!m);
  }
);

export const activeMachineInputs = createSelector(
  [assetsSelectors.machine_inputs],
  (inputs) => {
    return (
      inputs?.filter((item) => item.allow_filter && item.status === 'active') ||
      []
    );
  }
);

export default assetsSelectors;

import {Map} from 'leaflet';

import {MapEmbedConfiguration} from '../components/common/Map';

export const saveMapConfig = (
  map: Map,
  panel:
    | 'assets_tracking_report'
    | 'employee_tracking_report'
    | 'alarm_log_report'
    | 'cn_report'
    | 'nodes_report'
    | 'cn_tracking_report'
    | 'warning_hazard_report'
    | 'detection_log_report'
    | 'heatmap_report'
    | 'heatmap_event_logs'
    | undefined,
  focusMode: boolean
) => {
  const mapConfigurations: MapEmbedConfiguration[] = localStorage.getItem(
    'map_config'
  )
    ? JSON.parse(localStorage.getItem('map_config') ?? '')
    : [];
  const matchedIndex: number =
    mapConfigurations?.findIndex((it: any) => it?.panel === `${panel}`) ?? -1;

  const maxLng = map.getBounds().getEast();
  const minLng = map.getBounds().getWest();
  const maxLat = map.getBounds().getNorth();
  const minLat = map.getBounds().getSouth();
  const panelMapConfig: MapEmbedConfiguration = {
    zoom: map.getZoom(),
    bounds: [
      [minLat, minLng],
      [maxLat, maxLng],
    ],
    panel: panel,
    autoFocus: focusMode,
  };

  if (matchedIndex > -1) {
    mapConfigurations.splice(matchedIndex, 1);
  }

  mapConfigurations.push(panelMapConfig);
  localStorage.setItem('map_config', JSON.stringify(mapConfigurations));
};

export const fetchMapConfig = (
  panel:
    | 'assets_tracking_report'
    | 'employee_tracking_report'
    | 'alarm_log_report'
    | 'cn_report'
    | 'nodes_report'
    | 'cn_tracking_report'
    | 'warning_hazard_report'
    | 'detection_log_report'
    | 'heatmap_report'
    | 'heatmap_event_logs'
    | undefined
) => {
  const mapConfigurations: MapEmbedConfiguration[] = localStorage.getItem(
    'map_config'
  )
    ? JSON.parse(localStorage.getItem('map_config') ?? '')
    : [];
  const mapConfig: MapEmbedConfiguration | null =
    mapConfigurations?.find((it: any) => it?.panel === `${panel}`) ?? null;

  return mapConfig;
};

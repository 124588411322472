import {LoadingButton} from '@mui/lab';
import {Box, Checkbox, FormControlLabel, Grid} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {closeSnackbar, enqueueSnackbar} from 'notistack';
import {ChangeEvent, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {ExportField} from '../../../interfaces/Export';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';
import {HazardHeatmapReportDataTab} from './HazardHeatmapGrid';

enum ColumnsCount {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  SIX = 6,
  TWELVE = 12,
}

interface Props {
  value?: HazardHeatmapReportDataTab | null;
  exportFields?: ExportField[];
  columns?: ColumnsCount;
}

const xsVsColumns: {
  [value in ColumnsCount]: number;
} = {
  [ColumnsCount.ONE]: 12,
  [ColumnsCount.TWO]: 6,
  [ColumnsCount.THREE]: 4,
  [ColumnsCount.FOUR]: 3,
  [ColumnsCount.SIX]: 2,
  [ColumnsCount.TWELVE]: 1,
};

const HeatmapExportFieldForm = ({value, exportFields, columns = 3}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [fields, setFields] = useState<string[]>(
    exportFields
      ? exportFields
          .filter((field) => !field.hidden)
          .map((field) => field.field)
      : []
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    field: string
  ) => {
    if (checked) {
      fields.push(field);
      setFields([...fields]);
    } else {
      const index = fields.indexOf(field);
      fields.splice(index, 1);
      setFields([...fields]);
    }
  };

  const formik = useFormik<string[]>({
    initialValues: fields,
    onSubmit: async () => {
      await fetchData(fields);
    },
  });

  const fetchData = async (fields: string[]) => {
    setIsFetching(true);
    try {
      const params = {
        ...value?.params,
        export: 'csv',
        fields,
      };
      const resp = await API.get(`${apiBaseUrl}/hazard-ai/detection-heat-map.csv`, {
        params,
        responseType: 'blob',
      });
      saveFile(
        resp.data,
        `hazard_heatmap_${value?.params.date_start ?? dayjs().format('YYYY-MM-DD')}_${
          value?.params.date_end ?? dayjs().format('YYYY-MM-DD')
        }.csv`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <Box fontSize={24}>Select Fields to Export</Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container sx={{p: 1, height: 300, overflowY: 'auto'}}>
          {!!exportFields?.length &&
            exportFields.map((item: ExportField) => (
              <Grid item xs={xsVsColumns[columns]} key={item.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={fields.indexOf(item.field) !== -1}
                      onChange={(event, checked) => {
                        handleChange(event, checked, item.field);
                      }}
                    />
                  }
                  label={item.label}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          type="submit"
        >
          Export to Excel
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default HeatmapExportFieldForm;

import {ConfigurationResponseItem} from '../interfaces/Configuration';

export const getMergedConfigurations = (
  configuration?: ConfigurationResponseItem[] | null,
  newConfiguration?: ConfigurationResponseItem[]
) => {
  const mergedMyConfigurations = configuration?.map((i) => ({
    ...i,
    ...newConfiguration?.find(
      (n) => n.section === i.section && n.name === i.name
    ),
  }));
  return mergedMyConfigurations;
};

export const findConfiguration = (
  configurations: ConfigurationResponseItem[] | null,
  section: string,
  name: string
) => {
  return configurations?.find((i) => i.section === section && i.name === name);
};

import AddIcon from '@mui/icons-material/Add';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SaveIcon from '@mui/icons-material/Save';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {LoadingButton} from '@mui/lab';
import {
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MuiAppBar, {AppBarProps} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container, {ContainerProps} from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {styled} from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Image from 'mui-image';
import React, {ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink, useNavigate} from 'react-router-dom';

import AccessControl from '../components/common/AccessControl';
import Status from '../components/common/Status';
import {CompanyListButton} from '../components/company/buttons/CompanyListButton';
import MyAccountButton from '../components/profile/buttons/MyAccountButton';
import MyDarkModeSwitcher from '../components/profile/MyDarkModeSwitcher';
import {ProfileSettingsModal} from '../components/profile/ProfileSettingsModal';
import {useAppSelector} from '../hooks/redux';
import reduxActions from '../redux/actions';
import reduxSelectors from '../redux/selectors';
import {LOGO} from '../utils/logo';

interface Props {
  children?: ReactNode;
  contentContainerProps?: ContainerProps;
}

const drawerWidth: number = 240;

interface DashboardAppBarProps extends AppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DashboardAppBarProps>(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    top: '64px',
    height: 'calc(100% - 64px)',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    color: '#FFF',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.mode === 'dark' ? 'inheri' : '#185A7D',
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

export const DashboardLayout = ({children, contentContainerProps}: Props) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const muteSounds = useAppSelector(({app}) => app.muteSounds);
  const isLoggedIn = useAppSelector(reduxSelectors.auth.getLoggedIn);
  const assets = useAppSelector(({assets}) => assets);
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;

  const me = useAppSelector(({app}) => app.me);
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const activedDashboard = useAppSelector(({app}) => app.activedDashboard);
  const inSubmittingInSavingDashboard = useAppSelector(
    ({app}) => app.inSubmittingInSavingDashboard
  );
  const [sidebarOpened, setSidebarOpened] = React.useState(true);
  const [openedMenuItems, setOpenedMenuItems] = React.useState<string[]>([]);

  const toggleMuteSounds = () =>
    reduxActions.app.setApp(reduxDispatch, {muteSounds: !muteSounds});
  const toggleDrawer = () => setSidebarOpened(!sidebarOpened);

  const toggleMenuItem = (item: string) => {
    if (!openedMenuItems.includes(item)) {
      setOpenedMenuItems([...openedMenuItems, item]);
    } else {
      setOpenedMenuItems(openedMenuItems.filter((i) => i !== item));
    }
  };

  // fetch me
  const [isFetchConfiguratioinFailed, setIsFetchConfiguratioinFailed] =
    useState(false);
  const handleFetchConfigurations = async () => {
    setIsFetchConfiguratioinFailed(false);
    try {
      if (!me) {
        await reduxActions.app.fetchMe(reduxDispatch);
      }
      if (!myConfigurations) {
        await reduxActions.app.fetchMyConfigurations(reduxDispatch);
      }
    } catch {
      setIsFetchConfiguratioinFailed(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn && !me) {
      handleFetchConfigurations();
    }
  }, [isLoggedIn, me]);

  // set currentCompanyId
  useEffect(() => {
    if (isLoggedIn && me && !currentCompanyId) {
      reduxActions.app.setApp(reduxDispatch, {companyId: me.company_id});
    }
  }, [isLoggedIn, me, currentCompanyId]);

  // fetch assets
  const [isFetchAssetsFailed, setIsFetchAssetsFailed] = useState(false);
  const handleFetchAssets = async () => {
    setIsFetchAssetsFailed(false);
    try {
      await reduxActions.assets.fetchAssets(reduxDispatch);
    } catch {
      setIsFetchAssetsFailed(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn && me && currentCompanyId && !assets.isFetched) {
      handleFetchAssets();
    }
  }, [isLoggedIn, me, currentCompanyId, assets.isFetched]);

  useEffect(() => {
    setOpenedMenuItems([]);
  }, [sidebarOpened]);

  useEffect(() => {
    if (openedMenuItems.length && !sidebarOpened) {
      setSidebarOpened(true);
    }
  }, [openedMenuItems]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/auth/signin');
    }
  }, [isLoggedIn]);

  // Profile Menu
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const profileMenuOpened = Boolean(profileMenuAnchorEl);
  const [profileSettingsOpened, setProfileSettingsOpened] = useState(false);

  const onAddItem = () => {
    document.getElementById('btn-add-new-item')?.click();
  };

  const onSaveDashboard = () => {
    document.getElementById('btn-save-dashboard')?.click();
  };

  return assets.isFetched && currentCompanyId && me && myConfigurations ? (
    <>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />

        <AppBar
          open={sidebarOpened}
          position="absolute"
          sx={{
            background: (theme) =>
              theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
          }}
        >
          <Toolbar sx={{pr: '24px'}}>
            <IconButton
              edge="start"
              color="primary"
              onClick={toggleDrawer}
              sx={{
                mr: 2.2,
                color: (theme) =>
                  theme.palette.mode === 'dark' ? 'inherit' : '#185A7D',
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{mr: 2.2}}
              display="flex"
              columnGap="2px"
              alignItems="center"
            >
              <Image
                height={40}
                width="auto"
                src={isDarkMode ? LOGO.white_top : LOGO.black_top}
              ></Image>
              <Image
                height={25}
                width="auto"
                src={isDarkMode ? LOGO.white : LOGO.black}
              ></Image>
            </Box>

            <Typography noWrap fontSize={20} flexGrow={1} color="primary.main">
              {company?.name}
            </Typography>

            {activedDashboard && (
              <Typography
                noWrap
                fontSize={20}
                flexGrow={1}
                color="primary.main"
              >
                {activedDashboard?.name}
              </Typography>
            )}
            {activedDashboard && (
              <>
                <AccessControl
                  accessChecker={() => activedDashboard?.user_id === me?.id}
                >
                  <Button
                    sx={{minWidth: 'auto'}}
                    onClick={() => {
                      onAddItem();
                    }}
                  >
                    <AddIcon />
                  </Button>
                </AccessControl>

                <AccessControl
                  permissions={['patch::/dashboard/:id(\\d+)']}
                  accessChecker={() => activedDashboard?.user_id === me?.id}
                >
                  <LoadingButton
                    sx={{minWidth: 'auto'}}
                    loading={inSubmittingInSavingDashboard}
                    onClick={() => {
                      onSaveDashboard();
                    }}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </AccessControl>
              </>
            )}

            <Tooltip title={`Sounds ${muteSounds ? 'disabled' : 'enabled'}`}>
              <IconButton
                color={muteSounds ? 'error' : 'success'}
                onClick={toggleMuteSounds}
              >
                {muteSounds ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>
            </Tooltip>

            <MyDarkModeSwitcher />

            <IconButton
              color="primary"
              onClick={(event) => setProfileMenuAnchorEl(event.currentTarget)}
            >
              <PersonIcon />
            </IconButton>

            <Menu
              anchorEl={profileMenuAnchorEl}
              open={profileMenuOpened}
              onBackdropClick={() => setProfileMenuAnchorEl(null)}
            >
              <MyAccountButton component={MenuItem}>
                <PersonIcon sx={{mr: 1.5}} />
                My account
              </MyAccountButton>

              <Divider />

              <MenuItem
                onClick={() => {
                  setProfileSettingsOpened(true);
                  setProfileMenuAnchorEl(null);
                }}
              >
                <SettingsIcon sx={{mr: 1.5}} />
                Settings
              </MenuItem>

              <MenuItem
                onClick={() => {
                  reduxActions.auth.clearAuthTokens(reduxDispatch);
                  reduxActions.app.clearApp(reduxDispatch);
                  reduxActions.assets.clearAssets(reduxDispatch);
                  setProfileMenuAnchorEl(null);
                }}
              >
                <LogoutIcon sx={{mr: 1.5}} />
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={sidebarOpened}>
          <List
            component="nav"
            disablePadding
            sx={{
              /**
               * To Do: Danylo should update with theme
               *
               */
              svg: {
                path: {
                  fill: '#FFF',
                },
              },
            }}
          >
            {company?.primary_dashboard && (
              <Box
                borderBottom="1px solid"
                borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
              >
                <ListItemButton component={RouterLink} to="/dashboards/primary">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>

                  <ListItemText primary={company.primary_dashboard.name} />
                </ListItemButton>
              </Box>
            )}
            {me.favouriteDashboard?.map((dashboard) => (
              <Box
                key={dashboard.id}
                borderBottom="1px solid"
                borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
              >
                <ListItemButton
                  component={RouterLink}
                  to={`/dashboards/${dashboard.id}`}
                >
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>

                  <ListItemText primary={dashboard.name} />
                </ListItemButton>
              </Box>
            ))}
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/dashboards">
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>

                <ListItemText primary="Dashboards" />
              </ListItemButton>
            </Box>
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/panels">
                <ListItemIcon>
                  <QueryStatsIcon />
                </ListItemIcon>

                <ListItemText primary="Panels" />
              </ListItemButton>
            </Box>
            {/* <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton component={RouterLink} to="/indicator/panel">
                <ListItemIcon>
                  <DragIndicatorIcon />
                </ListItemIcon>

                <ListItemText primary="Indicator Panel" />
              </ListItemButton>
            </Box> */}
            <Box
              borderBottom="1px solid"
              borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
            >
              <ListItemButton onClick={() => toggleMenuItem('admin')}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
                {openedMenuItems.includes('admin') ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItemButton>
            </Box>
            <Collapse
              in={openedMenuItems.includes('admin')}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                sx={{
                  borderBottom: '1px solid',
                  borderColor: isDarkMode ? 'grey.800' : 'grey.200',
                }}
              >
                <Box>
                  <AccessControl permissions={['get::/configuration']}>
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/products"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Products"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  <AccessControl permissions={['get::/user']}>
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/users"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Users"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  <ListItemButton
                    component={RouterLink}
                    to="/admin/company-settings"
                    sx={{height: '40px', pl: 9}}
                  >
                    <ListItemText
                      primary="Company Settings"
                      primaryTypographyProps={{fontSize: 14}}
                    />
                  </ListItemButton>

                  <AccessControl
                    products={['proximity']}
                    permissions={['get::/proximity/import']}
                  >
                    <ListItemButton
                      component={RouterLink}
                      to="/admin/proximity-import"
                      sx={{height: '40px', pl: 9}}
                    >
                      <ListItemText
                        primary="Proximity Imports"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </ListItemButton>
                  </AccessControl>

                  {/* @TODO: do we need this page? */}
                  {/* <ListItemButton
                      component={RouterLink}
                      to="/admin/geo-fence"
                      sx={{ height: '40px', pl: 9 }}
                    >
                      <ListItemText primary="Geo Fence" primaryTypographyProps={{ fontSize: 14 }} />
                    </ListItemButton> */}

                  <AccessControl permissions={['get::/company']}>
                    <CompanyListButton
                      component={ListItemButton}
                      componentProps={{
                        sx: {height: '40px', pl: 9},
                      }}
                    >
                      <ListItemText
                        primary="SaaS Companies"
                        primaryTypographyProps={{fontSize: 14}}
                      />
                    </CompanyListButton>
                  </AccessControl>

                  <ListItemButton
                    component={RouterLink}
                    to="/admin/help"
                    sx={{height: '40px', pl: 9}}
                  >
                    <ListItemText
                      primary="Help"
                      primaryTypographyProps={{fontSize: 14}}
                    />
                  </ListItemButton>
                </Box>
              </List>
            </Collapse>
            {!hazard_ai_enabled && (
              <AccessControl permissions={['get::/subscription']}>
                <Box
                  borderBottom="1px solid"
                  borderColor={isDarkMode ? 'grey.800' : 'grey.200'}
                >
                  <ListItemButton component={RouterLink} to="/subscriptions">
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>

                    <ListItemText primary="Reporting" />
                  </ListItemButton>
                </Box>
              </AccessControl>
            )}
          </List>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              textAlign: 'center',
            }}
          >
            <Status short={!sidebarOpened} />
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.background.default,
            flexGrow: 1,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container
            maxWidth={false}
            sx={{
              py: 4,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
            {...contentContainerProps}
          >
            {children}
          </Container>
        </Box>
      </Box>

      <ProfileSettingsModal
        open={profileSettingsOpened}
        onClose={() => setProfileSettingsOpened(false)}
      />
    </>
  ) : (
    <Backdrop open={!assets.isFetched || !!me}>
      {!isFetchAssetsFailed && !isFetchConfiguratioinFailed ? (
        <CircularProgress color="inherit" />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={3}
        >
          <ErrorIcon color="error" fontSize="large" />
          {isFetchAssetsFailed ? (
            <Button variant="outlined" onClick={handleFetchAssets}>
              Reload Assets
            </Button>
          ) : isFetchConfiguratioinFailed ? (
            <Button variant="outlined" onClick={handleFetchConfigurations}>
              Reload Configurations
            </Button>
          ) : null}
        </Box>
      )}
    </Backdrop>
  );
};

import {DashboardEntity} from '../../../interfaces/Dashboard';
import {PanelCode} from '../../../utils/panels';
import DashboardPanelSelect from '../../dashboard-panels/DashboardPanelSelect';
import DashboardEntityItemPanel from './DashboardEntityItemPanel';

interface Props {
  value: DashboardEntity;
  entities?: DashboardEntity[];
  disabled?: boolean;
  onDelete?: (value: DashboardEntity) => void;
  onUpdate?: (value: DashboardEntity) => void;
  onOpenHistory?: (
    id: number | string | number[],
    entity:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const DashboardEntityItem = ({
  value,
  entities,
  disabled,
  onDelete,
  onUpdate,
  onOpenHistory,
}: Props) => {
  return (
    <>
      {value.type === 'panel' && value.panel ? (
        <DashboardEntityItemPanel
          value={value}
          entities={entities}
          disabled={disabled}
          onUpdate={(v) => onUpdate?.(v)}
          onDelete={(v) => onDelete?.(v)}
          onOpenHistory={onOpenHistory}
        />
      ) : (
        <DashboardPanelSelect
          onChange={(event) => {
            const code = event.target.value as PanelCode;
            onUpdate?.({
              ...value,
              type: 'panel',
              name: code,
              panel: {code},
            });
          }}
        />
      )}
    </>
  );
};

export default DashboardEntityItem;

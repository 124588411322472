import {Alert, Box, Checkbox} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import * as _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import usePrevious from '../../../hooks/usePrevious';
import {
  HazardAIMachineSummary,
  HazardAIMachineSummaryQuery,
  HazardAIMachineSummaryResponse,
} from '../../../interfaces/HazardAIMachineSummary';
import reduxSelectors from '../../../redux/selectors';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {usePanelFilter} from '../../dashboards/entities/DashboardEntityContext';

export interface HazardAISummaryGridConfig {
  selectedIds?: string[] | undefined;
  shownFields?: string[] | undefined;
  grid?: {
    limit?: number;
    page?: number;
  };
  params?: HazardAIMachineSummaryQuery;
}

export interface HazardAISummaryGridProps {
  value?: HazardAISummaryGridConfig;
  onChange?: (value: HazardAISummaryGridConfig) => VoidFunction;
}

const HazardAISummaryGrid = ({value, onChange}: HazardAISummaryGridProps) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const {filter} = usePanelFilter();
  const dataGridRef = useRef<DataGridRef>(null);

  const [fetchedData, setFetchedData] =
    useState<HazardAIMachineSummaryResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const defaultConfig = {
    selectedIds: [],
    shownFields: [
      'safeye_node_id',
      'safeye_node_name',
      'operation_time',
      'idle_time',
    ],
    grid: {
      page: 0,
      limit: 25,
    },
    params: value?.params || {},
  };
  const [config, setConfig] = useState<HazardAISummaryGridConfig>({
    ...defaultConfig,
    ...value,
  });

  const params = useMemo<HazardAIMachineSummaryQuery>(() => ({
    date_start: filter.params?.date_start ?? config.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
    date_end: filter.params?.date_end ?? config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
    safeye_node_id: _.has(filter.params, 'safeye_node_ids')
      ? filter.params?.safeye_node_ids
      : config?.params?.safeye_node_id,
    site_id: _.has(filter.params, 'site_ids')
      ? filter.params?.site_ids
      : config?.params?.site_id,
    limit: config.grid?.limit,
    page: config.grid?.page,
  }), [filter, config]);

  const fetchData = useCallback(
    async (params: HazardAIMachineSummaryQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/hazard-ai/machine-summary-grid`;
        const resp = await API.get<HazardAIMachineSummaryResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  /*************/
  /* data grid */
  /*************/

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params, config.grid]);

  const rows = fetchedData?.items || [];

  const cols: DataGridColumn<HazardAIMachineSummary>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.safeye_node_id)}
          onChange={() => toggleSelectItem(row.safeye_node_id)}
        />
      ),
    },
    {
      field: 'safeye_node_id',
      headerName: 'Node ID',
      sortable: true,
    },
    {
      field: 'safeye_node_name',
      headerName: 'Node Name',
      sortable: true,
    },
    {
      field: 'hazard_person',
      headerName: 'Hazard Person',
      sortable: true,
    },
    {
      field: 'warning_person',
      headerName: 'Warning Person',
      sortable: true,
    },
    {
      field: 'id_person',
      headerName: 'ID Person',
      sortable: true,
    },
    {
      field: 'hazard_vehicle',
      headerName: 'Hazard Vehicle',
      sortable: true,
    },
    {
      field: 'warning_vehicle',
      headerName: 'Warning Vehicle',
      sortable: true,
    },
    {
      field: 'id_vehicle',
      headerName: 'ID Vehicle',
      sortable: true,
    },
    {
      field: 'max_speed',
      headerName: 'Max Speed',
      sortable: true,
    },
    {
      field: 'operation_time',
      headerName: 'Operation Time',
      sortable: true,
    },
    {
      field: 'idle_time',
      headerName: 'Idle Time',
      sortable: true,
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields;
  }, [config]);

  const handleChangeShownFields = (fields: string[]) => {
    setConfig(
      update(config, {
        shownFields: {$set: fields},
      })
    );
    onChange?.(config);
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds || [];

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems.includes(i.safeye_node_id)),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    const selectedIds = selectedItems.includes(id)
      ? config.selectedIds?.filter((i) => i !== id)
      : [...(config.selectedIds || []), id];
    setConfig(
      update(config, {
        selectedIds: {
          $set: selectedIds,
        },
      })
    );
    onChange?.(config);
  };

  const selectAll = () => {
    setConfig(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => i.safeye_node_id) || [],
        },
      })
    );
    onChange?.(config);
  };

  const unselectAll = () => {
    setConfig(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
    onChange?.(config);
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length !== selectedRows.length) {
      setConfig(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i) => i.safeye_node_id),
          },
        })
      );
      onChange?.(config);
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <Box height="50%" bgcolor={isDarkMode ? 'background.default' : 'grey.100'}>
      {/* Errors */}
      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      {/* Chart */}
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={cols}
        size="small"
        pagination
        paginationMode="server"
        page={config.grid?.page}
        pageSize={config.grid?.limit}
        rowCount={fetchedData?.count}
        loading={fetchedInProgress}
        shownFields={shownFields}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        footerStart={
          <Box display="flex" alignItems="center" gap={3} px={1}>
            <Box
              display="flex"
              gap={0.5}
              alignItems="center"
              height="100%"
              whiteSpace="nowrap"
            >
              {selectedItems.length} selected
            </Box>
          </Box>
        }
        onShownFieldsChange={handleChangeShownFields}
        onPageChange={(v) => {
          setConfig(
            update(config, {
              grid: {
                page: {
                  $set: v,
                },
              },
            })
          );
          onChange?.(config);
        }}
        onPageSizeChange={(v) => {
          setConfig(
            update(config, {
              grid: {
                limit: {
                  $set: v,
                },
              },
            })
          );
          onChange?.(config);
        }}
      />
    </Box>
  );
};

export default HazardAISummaryGrid;

// SafeyeNodeItemsEditButton
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {SafeyeNode} from '../../../interfaces/SafeyeNode';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item?: SafeyeNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  onDone?: () => void;
}

const SafeyeNodeItemsEditButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onOpenItem,
  onOpenHistory,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            onOpenItem?.(item?.id, 'assign');
            setMenuAnchorEl(undefined);
          }}
        >
          {item?.asset_machine_id ? 'Reassign' : 'Assign to the machine'}
        </MenuItem>

        {item?.asset_machine_id && [
          <MenuItem
            onClick={() => {
              onOpenHistory?.(item?.id, 'hazard_ai_detection_log');
              setMenuAnchorEl(undefined);
            }}
            key="hazard_ai_detection_log"
          >
            Open AI Detection Log
          </MenuItem>,
          <MenuItem
            onClick={() => {
              onOpenHistory?.(item?.id, 'hazard_ai_heatmap');
              setMenuAnchorEl(undefined);
            }}
            key="hazard_ai_heatmap"
          >
            Open Heatmap Panel
          </MenuItem>,
        ]}
      </Menu>
    </>
  );
};

export default SafeyeNodeItemsEditButton;

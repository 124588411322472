import {AlarmHistory} from '../interfaces/AlarmsHistory';
import {CommtracNodeByCnEvent} from '../interfaces/CommtracNodeByCnEvent';
import {CommtracNodeHistory} from '../interfaces/CommtracNodeHistory';

interface MessageType {
  code: number;
  slug: string;
  name: string;
}

export const employeeMessageTypes: MessageType[] = [
  {
    code: 1,
    slug: 'location',
    name: 'Location',
  },
  {
    code: 2,
    slug: 'mo_message',
    name: 'Employee Originated Text',
  },
  {
    code: 3,
    slug: 'mt_message',
    name: 'Employee Terminated Text',
  },
  {
    code: 12,
    slug: 'check_in_out',
    name: 'Check In/Out',
  },
  {
    code: 14,
    slug: 'check_in_out_ack',
    name: 'Check In/Out Ack',
  },
  {
    code: 20,
    slug: 'configuration',
    name: 'Configuration',
  },
  {
    code: 21,
    slug: 'network_ack',
    name: 'Network Ack',
  },
  {
    code: 22,
    slug: 'message_read',
    name: 'Message Read',
  },
  {
    code: 23,
    slug: 'broadcast_response',
    name: 'Broadcast Response',
  },
];

export const assetMessageTypes: MessageType[] = [
  {
    code: 1,
    slug: 'location',
    name: 'Location',
  },
  {
    code: 20,
    slug: 'configuration',
    name: 'Configuration',
  },
  {
    code: 21,
    slug: 'network_ack',
    name: 'Network Ack',
  },
];

export const communicationNodeMessageTypes: MessageType[] = [
  {
    code: 4,
    slug: 'heartbeat',
    name: 'Heartbeat',
  },
  {
    code: 20,
    slug: 'configuration',
    name: 'Configuration',
  },
  {
    code: 21,
    slug: 'network_ack',
    name: 'Network Ack',
  },
];

export const commtracNodeByNodeMessageTypes: MessageType[] = [
  {
    code: 1,
    slug: 'location',
    name: 'Location',
  },
  {
    code: 1,
    slug: 'check_in_out',
    name: 'Check In/Out',
  },
];

export const getCommtracNodeHistoryId = (item: CommtracNodeHistory) => {
  return `${item.id}-${item.message_type}`;
};

export const getCommtracNodeByCnHistoryId = (item: CommtracNodeByCnEvent) => {
  return `${item.id}-${item.message_type_id}`;
};

export const getAlarmNodeHistoryId = (item: AlarmHistory) => {
  return `${item.id}-${item.message_type}`;
};

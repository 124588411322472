import {Button, ButtonGroup} from '@mui/material';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import MachineDetailsReportBar from './MachineDetailsReportBar';
import MachineDetailsReportLine from './MachineDetailsReportLine';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const MachineDetailsReport = (props: Props) => {
  const [panel] = usePanel();

  const [chartType, setChartType] = useState<'bar' | 'line'>(
    props.value?.chartType ?? 'bar'
  );

  useEffect(() => {
    props?.onUpdate?.({...props.value, chartType});
  }, [chartType]);

  return (
    <>
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <DashboardPanelActionsSlot>
        <ButtonGroup>
          <Button
            size="small"
            variant={chartType === 'bar' ? 'contained' : 'outlined'}
            onClick={() => setChartType('bar')}
          >
            Bar
          </Button>

          <Button
            size="small"
            variant={chartType === 'line' ? 'contained' : 'outlined'}
            onClick={() => setChartType('line')}
          >
            Graph
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {chartType === 'bar' ? (
        <MachineDetailsReportBar
          value={props.value}
          onUpdate={props.onUpdate}
        />
      ) : chartType === 'line' ? (
        <MachineDetailsReportLine
          value={props.value}
          onUpdate={props.onUpdate}
        />
      ) : null}
    </>
  );
};

export default MachineDetailsReport;

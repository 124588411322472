import dayjs from 'dayjs';

import {EventSummaryProductValue} from '../interfaces/Event';
import {ALARM_MODULE_SOUNDS} from './alarm-modules';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_SOUNDS,
  MINER_EVENT_COLORS,
  MINER_EVENT_SOUNDS,
} from './commtrac-nodes';
import {tweakLabelForMiner} from './macAddress';
import {CN_EVENT_COLORS, CN_EVENT_SOUNDS} from './nodes';
import {SAFEYE_NANO_EVENT_SOUNDS} from './safeyeNodes';

export const getEventDescription = (
  template: string,
  textReplacement: string[]
) => {
  if (!template) {
    return null;
  }

  if (!textReplacement || !textReplacement.length) {
    return tweakLabelForMiner(template);
  }
  return tweakLabelForMiner(
    template.replace(/(%s)\d{0,}/gim, (match) => {
      if (match === '%s') {
        return textReplacement[0];
      }
      const [, index] = match.split('%s');

      return textReplacement[+index - 1] ? textReplacement[+index - 1] : '';
    })
  );
};

export const getLastSummaryEventDescription = (
  product: EventSummaryProductValue | undefined
) => {
  if (product?.count && product?.recent) {
    const date = dayjs(product.recent.date).format('YYYY-MM-DD HH:mm:ss');
    const desc = getEventDescription(
      product?.recent?.text_template,
      product?.recent?.text_replacements
    );
    return `${date} - ${desc}`;
  }

  return 'Events';
};

export const CONNECT_EVENT_SOUNDS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_SOUNDS,
  ...ASSET_EVENT_SOUNDS,
  ...MINER_EVENT_SOUNDS,
  ...ALARM_MODULE_SOUNDS,
};

export const CONNECT_EVENT_COLORS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_COLORS,
  ...ASSET_EVENT_COLORS,
  ...MINER_EVENT_COLORS,
};

export const HAZARD_AI_EVENT_SOUNDS: {[key: number]: string} = {
  ...SAFEYE_NANO_EVENT_SOUNDS,
};

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {activeMachineInputs} from '../../redux/assets/selectors';

interface MachineInputSelectProps {
  value?: MachineInputSelectV2Value;
  label?: string;
  size?: 'small' | 'medium';
  onChange?: (value: MachineInputSelectV2Value) => void;
}

interface MachineInputSelectV2Value {
  [id: string]: 0 | 1 | null | undefined;
}

export const MachineInputSelectV2: React.FC<MachineInputSelectProps> = ({
  value = {},
  label,
  size,
  onChange = () => {},
}) => {
  const options = useSelector(activeMachineInputs);

  const renderSelectGroup = useCallback((i: any) => {
    const items = [
      <MenuItem value={`${i.id}`}>Any {i.name}</MenuItem>,
      <MenuItem value={`${i.id}-1`}>{i.name} is On</MenuItem>,
      <MenuItem value={`${i.id}-0`}>{i.name} is Off</MenuItem>,
    ];
    return [
      <Typography sx={{px: 2, fontWeight: '600'}}>{i.name}</Typography>,
      items,
      <Divider />,
    ];
  }, []);

  return (
    <FormControl fullWidth size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        label={label ?? 'Inputs'}
        value={options.map((i) =>
          value[i.id] === undefined ? `${i.id}` : `${i.id}-${value[i.id]}`
        )}
        renderValue={() =>
          options
            .map((i) =>
              value[i.id] === undefined
                ? `Any ${i.name}`
                : `${i.name} ${value[i.id] === 1 ? 'is On' : value[i.id] === 0 ? 'is Off' : ''}`
            )
            .join(', ')
        }
        onChange={(event) => {
          let data: any = {};
          const newValue = {...value};
          (event.target.value as any[])?.forEach((item) => {
            const [k, v] = item.split('-');
            data = {
              [k]: v === '1' ? 1 : v === '0' ? 0 : undefined,
            };
          });
          Object.keys(newValue).forEach((key) => {
            if (
              !(event.target.value as string[])
                ?.map((item) => item.split('-')[0])
                ?.includes(key)
            ) {
              delete newValue[key];
            }
          });
          onChange({...newValue, ...data});
        }}
        MenuProps={{
          sx: {'.MuiPaper-root': {minWidth: '200px !important'}},
        }}
      >
        {options?.map((p) => renderSelectGroup(p))}
      </Select>
    </FormControl>
  );
};

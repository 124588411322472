import {Alert, Backdrop, Box, CircularProgress, useTheme} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import * as _ from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  HazardAIMachineUtilizationChartQuery,
  HazardAIMachineUtilizationChartResponse,
} from '../../../interfaces/HazardAIMachineUtilizationChart';
import reduxSelectors from '../../../redux/selectors';
import {
  usePanel,
  usePanelFilter,
} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SafeyeNodeSelector} from '../../selectors/SafeyeNodeSelector';
import {SitesSelect} from '../../selectors/SitesSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import HazardAISummaryGrid from './HazardAISummaryGrid';

enum ChartLabels {
  operation_time = 'Operation Time',
  idle_time = 'Idle Time',
}

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

const HazardAIMachineUtilizationChart = (props?: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const theme = useTheme();
  const [panel] = usePanel();
  const {filter, setFilter} = usePanelFilter();

  const [fetchedData, setFetchedData] =
    useState<HazardAIMachineUtilizationChartResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [hasData, setHasData] = useState(false);

  const params = useMemo<HazardAIMachineUtilizationChartQuery>(() => ({
    date_start: filter.params?.date_start ?? props?.value?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
    date_end: filter.params?.date_end ?? props?.value?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
    safeye_node_id: _.has(filter.params, 'safeye_node_ids')
      ? filter.params?.safeye_node_ids
      : props?.value?.params?.safeye_node_id,
    site_id: _.has(filter.params, 'site_ids')
      ? filter.params?.site_ids
      : props?.value?.params?.site_id,
  }), [filter]);

  const data = useMemo(() => {
    if (fetchedData) {
      return [
        {name: ChartLabels.operation_time, value: fetchedData.operation_time},
        {name: ChartLabels.idle_time, value: fetchedData.idle_time},
      ];
    }
  }, [fetchedData]);

  useEffect(() => {
    if (params) {
      fetchData(params);
      props?.onUpdate?.({...props.value, params});
    }
  }, [params]);

  const fetchData = useCallback(
    async (params: HazardAIMachineUtilizationChartQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/hazard-ai/machine-utilization`;
        const resp = await API.get<HazardAIMachineUtilizationChartResponse>(
          endpoint,
          {params}
        );
        setFetchedData(resp.data);
        setHasData(resp.data.operation_time + resp.data.idle_time > 0);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  const renderCustomizedLabel = (options: any) => {
    const radius =
      options.innerRadius + (options.outerRadius - options.innerRadius) * 0.5;
    const x =
      options.cx + radius * Math.cos((-options.midAngle * Math.PI) / 180);
    const y =
      options.cy + radius * Math.sin((-options.midAngle * Math.PI) / 180);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > options.cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(options.percent * 100).toFixed(2)} %`}
      </text>
    );
  };

  const CustomTooltip = (options: any) => {
    if (options.active && options.payload?.length) {
      const item = options.payload[0].payload;
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.secondary,
            borderColor: theme.palette.text.secondary,
            borderStyle: 'solid',
            borderWidth: '1px',
            padding: '4px 8px',
          }}
        >
          <span>{`${item.name} : ${item.value}`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="calc(100% - 38px)"
      width="100%"
      overflow="hidden"
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      {/* Filters */}
      <Box display="flex" gap={1} py={1}>
        <Box maxWidth={400} minWidth={200}>
          <SafeyeNodeSelector
            multiple
            fullWidth
            size="small"
            value={params.safeye_node_id as unknown as number[]}
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    safeye_node_ids: {
                      $set: v as unknown as string[],
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box maxWidth={400} minWidth={200}>
          <SitesSelect
            multiple
            fullWidth
            size="small"
            value={params.site_id}
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    site_ids: {
                      $set: v as number[],
                    },
                  },
                })
              );
            }}
          />
        </Box>
        <Box maxWidth={400}>
          <DateRangeSelect
            value={[
              dayjs(params.date_start).toDate(),
              dayjs(params.date_end).toDate(),
            ]}
            size="small"
            onChange={(v) => {
              setFilter(
                update(filter, {
                  params: {
                    date_start: {
                      $set: v?.[0]
                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                        : undefined,
                    },
                    date_end: {
                      $set: v?.[1]
                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                        : undefined,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      </Box>

      {/* Errors */}
      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 56px)"
        gap={1}
      >
        {/* Chart */}
        <Box
          width="100%"
          height="50%"
          bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
        >
          {!fetchedInProgress && hasData && (
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius="95%"
                  dataKey="value"
                >
                  <Cell key="0" fill="red" />
                  <Cell key="1" fill="blue" />
                </Pie>
                <TooltipRecharts cursor={false} content={<CustomTooltip />} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
          {(fetchedInProgress || !hasData) && (
            <Box
              display="flex"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {fetchedInProgress ? 'Loading...' : 'No data to display'}
            </Box>
          )}
        </Box>

        {/* Grid */}
        <HazardAISummaryGrid value={{
          ...props?.value,
          shownFields: props?.value?.shownFields || [
            'safeye_node_id',
            'safeye_node_name',
            'operation_time',
            'idle_time',
          ],
        }} />
      </Box>
    </Box>
  );
};

export default HazardAIMachineUtilizationChart;

import {Box} from '@mui/material';
import update from 'immutability-helper';
import {FC, useEffect, useMemo} from 'react';
import {Layout, Responsive, WidthProvider} from 'react-grid-layout';

import {DashboardEntity, PanelFilter} from '../../../interfaces/Dashboard';
import {isPresent} from '../../../utils/type-guards';
import {useDashboard, useDashboardFilter} from '../DashboardContext';
import {DashboardEntityProvider} from './DashboardEntityContext';
import DashboardEntityItem from './DashboardEntityItem';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface DashboardEntityListProps {
  disabled?: boolean;
  entities: DashboardEntity[];
  onUpdate?: (entities: DashboardEntity[]) => void;
  onEntityUpdate?: (entity: DashboardEntity) => void;
  onOpenHistory?: (
    id: number | string | number[],
    entity:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
  ) => void;
}

const DashboardEntityList: FC<DashboardEntityListProps> = ({
  disabled,
  entities,
  onUpdate,
  onEntityUpdate,
  onOpenHistory,
}) => {
  const layout = useMemo(
    () =>
      entities.map((item) => ({
        i: item.id,
        x: item.grid.x,
        y: item.grid.y,
        w: item.grid.w,
        h: item.grid.h,
        minW: 4,
        minH: 2,
        maxW: 12,
        maxH: 24,
      })),
    [entities]
  );

  useEffect(() => {
    if (entities.length === 1) {
      const newEntity = {...entities[0]};
      const entityContainer = document.getElementById('entity-container');
      newEntity.grid.h = entityContainer
        ? entityContainer.clientHeight / 162
        : 5;
      onUpdate?.(
        update(entities, {
          $set: [newEntity],
        })
      );
    }
  }, [entities.length]);

  const onLayoutChange = (layout: Layout[]) => {
    const newEntities: DashboardEntity[] = layout
      .map(({i, x, y, h, w}) => {
        const item = entities.find((item) => item.id === i);
        if (item) {
          return {...item, grid: {x, y, h, w}};
        }
      })
      .filter(isPresent);

    onUpdate?.(newEntities);
  };

  const [dashboard, dispatchDashboard] = useDashboard();
  const {filter, setFilter} = useDashboardFilter();

  const setPanelFilter = (value: PanelFilter, id: string) => {
    setFilter?.({
      ...filter,
      [id]: value,
    });
  };

  const handleChangeEntity = (entity: DashboardEntity) => {
    dispatchDashboard({
      type: 'SetEntity',
      payload: entity,
    });
    onEntityUpdate?.(entity);
  };

  const handleDeleteEntity = (id: string) => {
    dispatchDashboard({
      type: 'DeleteEntity',
      payload: id,
    });
  };

  return (
    <Box
      sx={{
        flex: entities?.length === 1 ? 1 : 'unset',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[100] : null,
        // '>.react-grid-layout': {
        //   minHeight: entities?.length === 1 ? '100%' : 'unset',
        // },
      }}
    >
      <ResponsiveReactGridLayout
        className="layout"
        draggableHandle=".entity-drag-button"
        layouts={{lg: layout}}
        cols={{lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
        onLayoutChange={onLayoutChange}
      >
        {dashboard.data?.entities?.map((item) => {
          const panelFilter = filter[item.id];
          return (
            <Box
              width="100%"
              minHeight={entities?.length === 1 ? '100%' : 'unset'}
              key={item.id}
              data-id={item.id}
              data-panel-code={item.panel?.code}
            >
              <DashboardEntityProvider
                value={item}
                onChange={handleChangeEntity}
                filter={{
                  filter: panelFilter,
                  setFilter: (value: PanelFilter) =>
                    setPanelFilter(value, item.id),
                }}
              >
                <DashboardEntityItem
                  disabled={disabled}
                  value={item}
                  entities={entities}
                  onDelete={() => handleDeleteEntity(item.id)}
                  onUpdate={handleChangeEntity}
                  onOpenHistory={onOpenHistory}
                />
              </DashboardEntityProvider>
            </Box>
          );
        })}
      </ResponsiveReactGridLayout>
    </Box>
  );
};

export default DashboardEntityList;

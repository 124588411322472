import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Button, Divider, IconButton, Tab, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import AssetHumanExport from '../asset-human/AssetHumanExport';
import AssetHumanExportExcel from '../asset-human/AssetHumanExportExcel';
import AssetHumanImport from '../asset-human/AssetHumanImport';
import AssetMachineExport from '../asset-machine/AssetMachineExport';
import AssetMachineExportExcel from '../asset-machine/AssetMachineExportExcel';
import AssetMachineImport from '../asset-machine/AssetMachineImport';
import ModalFixed from '../common/ModalFixed';
import {CommtracNodesReportData} from '../dashboard-panels/ConnectView/CommtracNodesReport';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  value?: CommtracNodesReportData;
  onSubmitted?: () => void;
}

const TABS = [
  {
    value: 'employees',
    label: 'Employees',
  },
  {
    value: 'assets',
    label: 'Assets',
  },
] as const;

type TabValue = (typeof TABS)[number]['value'];

const CommtracNodeImportExportButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  value,
  onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<TabValue>('employees');
  const selectedTab = useMemo(
    () => TABS.find((tab) => tab.value === activeTab),
    [activeTab]
  );

  return (
    <>
      <Tooltip title="Import/Export">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <ImportExportIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Import/Export {selectedTab?.label}</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TabContext value={activeTab}>
              <Box>
                <TabList variant="fullWidth">
                  {TABS.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      onClick={() => setActiveTab(tab.value)}
                    />
                  ))}
                </TabList>
              </Box>

              <TabPanel value="employees" sx={{p: 0}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  gap={3}
                >
                  <Divider>Export</Divider>
                  <AssetHumanExport />
                  <AssetHumanExportExcel value={value} />
                  <Divider>Import</Divider>
                  <AssetHumanImport onSubmitted={onSubmitted} />
                </Box>
              </TabPanel>

              <TabPanel value="assets" sx={{p: 0}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  gap={3}
                >
                  <Divider>Export</Divider>
                  <AssetMachineExport />
                  <AssetMachineExportExcel value={value} />
                  <Divider>Import</Divider>
                  <AssetMachineImport
                    onSubmitted={() => {
                      setIsOpened(false);
                      onSubmitted?.();
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default CommtracNodeImportExportButton;
